import "../_style.scss";
import { useState } from "react";
import { useGetBillsQuery } from "../../../redux/apiSlice/billsApiSlice";
import { useGetFleetCostsByCostTypeV2Query } from "../../../redux/apiSlice/fleetCostSlice";
import {useVariableValue} from "@devcycle/react-client-sdk";
import {useGetCarFinancingQuery,useGetFleetKmQuery} from "../../../redux/apiSlice/dashboardApiSlice";
import { useGetUserQuery } from "../../../redux/apiSlice/userApiSlice";
import { calculateKmDriven } from "../../../actions/kmDriven.action";
import moment from "moment";
import {
    MetricCard,
    MetricCardBody,
    MetricCardHeader,
    MetricCardIcon,
    MetricCardTitle
} from "../../../components/MetricCard";
import { Skeleton } from "../../../components/Skeleton";
import { formatNumber } from "../../../actions/formateNumbers.actions";
import { ReactComponent as DocumentIcon } from "../../../assets/svg/icon-document.svg";
import { ReactComponent as PaperclipIcon } from "../../../assets/svg/paperclip-icon.svg";
import FormModal from "../../../components/FormModal";
import CarFinancingGridTable from "./CarFinancingGridTable";import { useNavigate } from "react-router-dom";
import { costPageKey } from "../../../components/NavBar";

export const FleetManagementSection = ({ labels }) => {
    const useNewCostApi = useVariableValue(costPageKey, false)
    const navigate = useNavigate();
    const handleSwitchPage = (divName) => {

        navigate(divName)
    }
    const [isFianceModalVisible, setIsFianceModalVisible] = useState(false)
    // Add 6 months to today
    const inSixMonths = moment(new Date()).add(6, 'months').format("YYYY-MM-DD");

    // Today
    const today = moment(new Date()).format("YYYY-MM-DD");

    // One month ago
    const oneMonthAgo = moment(new Date()).subtract(1, 'months').format("YYYY-MM-DD");

    const {
        data: userData,
    } = useGetUserQuery()
    const customerId = userData?.customer;

    const {
        data: billData,
        isFetching: billIsFetching,
        isError: billIsError,
    } = useGetBillsQuery();

    const {
        data: costData,
        isFetching: costIsFetching,
        isError: costIsError,
    } = useGetFleetCostsByCostTypeV2Query({
        startDt: oneMonthAgo,
        endDt: today
    });

    const {
        data: carFinancingData,
        isFetching: carFinancingIsFetching,
        isError: carFinancingIsError,
    } = useGetCarFinancingQuery({
        includeDocuments: false,
        endEndDate: inSixMonths
    }
    );

    const {
        data: kmData,
        isFetching: kmDataIsFetching,
        isError: kmDataIsError,
        isSuccess: kmDataIsSuccess,
    } = useGetFleetKmQuery({
        customerId: customerId,
        startDt: oneMonthAgo,
        endDt: today
    });
    const {kmDriven} = calculateKmDriven(kmData)

    const costPerKm = kmDriven > 0 ? (costData?.totalTco || 0)/ kmDriven : 0;
   
    // 1 is the statusId for Paid bills
    const unpaidBillCount = billData?.filter(bill => bill.statusId !== 1)?.length

    const unpaidBillAmount = billData?.filter(bill => bill.statusId !== 1)?.reduce((acc, bill) => acc + bill.openAmount, 0)



    return (
        <div className="fleet-management-content">
            <MetricCard onClick={() => {
                if (useNewCostApi) {
                    handleSwitchPage("costs-v2?view=costtype")
                } else {
                    handleSwitchPage("costs?view=costtype")
                }
            }} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={costIsError}>
                <MetricCardHeader>
                    <MetricCardTitle>
                        {labels?.homePage?.metrics?.totalFleetCosts} ({moment(oneMonthAgo).format("DD.MM.YY")} - {moment(today).format("DD.MM.YY")})
                    </MetricCardTitle>
                </MetricCardHeader>
                <MetricCardBody>
                    {costIsFetching ? <Skeleton height="25px" width="100%" /> : `CHF ${formatNumber(costData?.totalTco)}`}
                </MetricCardBody>
            </MetricCard>

            <MetricCard onClick={() => setIsFianceModalVisible(true)} className="card-with-hover" style={{ flexBasis: "250px", flexGrow: 1 }} hidden={carFinancingIsError}>
                <MetricCardHeader>
                    <MetricCardTitle>
                        {labels?.homePage?.metrics?.leasingDateUntil} {moment(inSixMonths).format("DD.MM.YYYY")}
                    </MetricCardTitle>
                </MetricCardHeader>
                <MetricCardBody>
                    <MetricCardIcon>
                        <DocumentIcon width={19} height={19} />
                    </MetricCardIcon>
                    {carFinancingIsFetching ? <Skeleton height="25px" width="100%" /> : `${carFinancingData?.length} ${labels?.carsPage?.titles?.vehicles}`}
                </MetricCardBody>
            </MetricCard>

            {/* Modal activated by the card above */}
            <FormModal
                style={{ minWidth: "800px" }}
                show={isFianceModalVisible}
                onClose={() => setIsFianceModalVisible(false)}
                title={<div>
                    {labels?.carsPage?.titles?.financing}
                </div>

                }
            >
                <p>{labels?.homePage?.metrics?.financingDataExplaination} ({moment(new Date()).add(6, 'months').format("DD.MM.YYYY")}).</p>
                <CarFinancingGridTable data={carFinancingData}></CarFinancingGridTable>
            </FormModal>


            {/* // cards hidden if api fails, instead of keep showing skeleton */}
            <MetricCard onClick={() => {handleSwitchPage("/bills")}} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={billIsError}>
                <MetricCardHeader>
                    <MetricCardTitle>
                        {labels?.homePage?.metrics?.notYetPaid}
                    </MetricCardTitle>
                </MetricCardHeader>
                <MetricCardBody>
                    <MetricCardIcon>
                        <PaperclipIcon width={19} height={19} />
                    </MetricCardIcon>
                    {billIsFetching ? <Skeleton height="25px" width="100%" /> : `${unpaidBillCount} ${labels?.billsPage?.titles?.bills}`}
                </MetricCardBody>
            </MetricCard>

            <MetricCard onClick={() => {handleSwitchPage("/bills")}} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={billIsError}>
                <MetricCardHeader>
                    <MetricCardTitle>
                        {labels?.homePage?.metrics?.pendingBillAmount}
                    </MetricCardTitle>
                </MetricCardHeader>
                <MetricCardBody style={{ color: "#B42318" }}>
                    {billIsFetching ? <Skeleton height="25px" width="100%" /> : `CHF ${formatNumber(unpaidBillAmount)}`}
                </MetricCardBody>
            </MetricCard>
            <MetricCard style={{ flexGrow: 0.25, minWidth: '300px' }} hidden={costIsError || kmDataIsError}>
                <MetricCardHeader>
                    <MetricCardTitle>
                        {labels?.homePage?.metrics?.fleetCostsPerKm} ({moment(oneMonthAgo).format("DD.MM.YY")} - {moment(today).format("DD.MM.YY")})
                    </MetricCardTitle>
                </MetricCardHeader>
                <MetricCardBody>
                    {costIsFetching || kmDataIsFetching || !kmDataIsSuccess ? (
                        <Skeleton height="25px" width="100%"/>
                    ) :  `CHF ${costPerKm?.toFixed(2)}`
                }   
                </MetricCardBody>
            </MetricCard>
        </div>
    )
}