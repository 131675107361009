export const dataCrossTabTransform = (data) => {
    let cars = JSON.parse(JSON.stringify(data))
    let uniqueFields = []
    let uniqueFieldDescriptions = []
    cars.forEach(entry => {
        if (entry.additionalCarInformation?.customerFields) {
            const {customerFields} = entry.additionalCarInformation;
            const customerFieldsNewFormat = {}
            customerFields.forEach((field) => {
                    const fieldName = field.fieldName;
                    //const fieldDes = field.description;
                    customerFieldsNewFormat[fieldName] = field.value;

                    //The following code is commented out because the uniqueFields and Descriptions are now hardcoded
                    /*
                    if (!uniqueFields.includes(fieldName)){
                        uniqueFields.push(fieldName)
                        // in case the description is not specified in aax
                        if (fieldDes){
                            uniqueFieldDescriptions.push(fieldDes)
                        }else(
                            uniqueFieldDescriptions.push(fieldName)
                        )
                    }
                     */

                }
            )
            uniqueFields = [
                "customerFieldReserve2",
                "customerFieldReserve3",
                "costCenter",
                "inventoryIdentification",
                "foreignKey",
                "customerFieldReserve1"
            ]
            uniqueFieldDescriptions = [
                "Customer field Reserve 2",
                "Customer field Reserve 3",
                "Cost center",
                "Inventory identification",
                "Foreign key",
                "Customer field Reserve 1"
            ]
            entry.additionalCarInformation.customerFields = customerFieldsNewFormat
        }

    })
    return {cars, uniqueFields, uniqueFieldDescriptions}
}
export const dataCrossTabTransformBillPostionsQuery = (positions) => {
    let billPositions=[]
    try {
        billPositions = JSON.parse(JSON.stringify(positions))
        billPositions.forEach(position => {
            if(position.car?.customerFields){
                const customerFields = position.car.customerFields;
                const customerFieldsNewFormat = {}
                customerFields.forEach((field) => {
                        const fieldName = field.fieldName;
                        //const fieldDes = field.description;
                        customerFieldsNewFormat[fieldName] = field.value;
                    }
                )
                position.car.customerFields=customerFieldsNewFormat
            }
        })
    }catch (e){

    }

    const uniqueFields = [
        "customerFieldReserve2",
        "customerFieldReserve3",
        "costCenter",
        "inventoryIdentification",
        "foreignKey",
        "customerFieldReserve1"
    ]
    const uniqueFieldDescriptions = [
        "Customer field Reserve 2",
        "Customer field Reserve 3",
        "Cost center",
        "Inventory identification",
        "Foreign key",
        "Customer field Reserve 1"
    ]
    return {billPositions, uniqueFields, uniqueFieldDescriptions}
}