// endpoint to download language labels
import { apiSlice } from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";
import {filterOutEmptyFields, mergeDeep} from "../../actions/deepMerge.actions";

export const labelsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        getLabels: builder.query({
            query: (language) => ({
                    url: API_FAMILY_CONST.LABEL,
                    // cache for 10min
                    keepUnusedDataFor: 600,
                    headers: {
                        'Accept-Language': `${language}`,
                    }
                }
            ),
            transformResponse: res => {
                let filteredRes= filterOutEmptyFields(res)
                return mergeDeep(filteredRes);
            },
            providesTags: (result,error, arg) => [{ type: 'Label', id: arg }],
        })
    })
})

export const {
    useGetLabelsQuery, useLazyGetLabelsQuery
} = labelsApiSlice