import {createSlice} from "@reduxjs/toolkit";

export const dateSlice = createSlice({
    name: "date",
    initialState: {
        // Saves the selected date range for /costs page
        fleetCostDates: {
            startDate: null,
            endDate: null,
            allTime: false
        },
        // Saves selected date range for the costs tab of all cars on /cars/VIN
        carCostDates: {
            startDate: null,
            endDate: null,
            allTime: false
        },
    },
    reducers: {
        setFleetCostDates: (state, action) => {
            state.fleetCostDates = action.payload
        },
        setCarCostDates: (state, action) => {
            state.carCostDates = action.payload
        },
    }
})

export const {setFleetCostDates, setCarCostDates} = dateSlice.actions;

export default dateSlice.reducer;