// endpoint to get all driver related information
import { apiSlice } from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";

export const driversApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDrivers: builder.query({
            query: () => API_FAMILY_CONST.DRIVERS+"/drivers",
            method: 'GET',
            //caching for 10 min
            keepUnusedDataFor: 600,
            providesTags: ['Drivers']
        }),
        updateDrivers: builder.mutation({
            query(data) {
                const parentCustomer=data.parentCustomer
                return {
                    url: API_FAMILY_CONST.DRIVERS+"/"+parentCustomer +"/driver",
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: (result, error) =>{
                return error ? [] : ['Drivers']
            }
        }),
        // TODO: change the name of this endpoint to addOrUpdateDriverToCar
        addDriverToCar: builder.mutation({
            query(data) {
                const organisationCustomerId = data.organisationCustomerId
                const carId = data.carId
                return {
                    url: API_FAMILY_CONST.DRIVERS + "/" + organisationCustomerId +"/car/" + carId,
                    method: 'POST',
                    body: data?.body,
                }
            },
            invalidatesTags: (result, error, queryArg) =>{
                //  If the queryArg.skipTagInvalidation is true, then we don't want to invalidate the cache
                // (I use this to avoid double getDrivers API calls in AddRelationModal)
                return (error || queryArg?.skipTagInvalidation) ? [] : ['Drivers']
            }
        }),
        createDriver: builder.mutation({
            query(data) {
                const body = {...data}
                const organisationId = body.organisationId
                delete body.organisationId
                return {
                    url: API_FAMILY_CONST.DRIVERS + "/" + organisationId + "/driver",
                    method: 'POST',
                    body: body,
                }
            },
            invalidatesTags: (result, error) =>{
                return error ? [] : ['Driver']
            }
        }),
        getOrganisations: builder.query({
            query: () => API_FAMILY_CONST.DRIVERS + `/manager/availableOrganisations`,
            method: "GET",
            providesTags: ['Organisations'],
        })
    })
})

export const {
    useGetDriversQuery, useUpdateDriversMutation, useCreateDriverMutation, useAddDriverToCarMutation, useGetOrganisationsQuery
} = driversApiSlice