// import external
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentLanguage} from "../../redux/labelsSlice/labelsSlice";
import {useLocation} from "react-router-dom";
// import api slices
import {useLazyGetLabelsQuery} from "../../redux/apiSlice/labelsApiSlice";
// import styling
import "./_style.scss";
/**
 * @param {*} props (not used)
 * Contains the api call to download language labels when clicked. The api response is also dispatched to redux
 * @returns
 */

const PageFooter = (props) => {
    // get current location
    let location = useLocation();
    // get current language selection state
    const currentLanguage = useSelector(state=> state.labels.currentLanguage)
    // change language selection state
    const dispatch = useDispatch()

    const [triggerLabelDownload] = useLazyGetLabelsQuery()

    const downloadLabel = async (event) => {
        event.preventDefault();
        const language = event.target.textContent
        if(!location.pathname.includes("login")){
            // Note: the trigger function takes a second argument of preferCacheValue?: boolean in the event you want to
            // skip making a request if cached data already exists.
            let labels = await triggerLabelDownload(language,true)
            if (labels.isSuccess) {
                dispatch(setCurrentLanguage({language:language, labels:labels.data}))
            }
        }else{
            dispatch(setCurrentLanguage({language:language}))
        }

    }
    return (
        <div className="footer-wrapper">
            <div className="language-list">
                <div className="language-wrapper" >
                    <div onClick={downloadLabel} className={currentLanguage==="DE"?"short-border":""}>
                        DE
                    </div>
                </div>
                <div className="language-wrapper" >
                    <div onClick={downloadLabel} className={currentLanguage==="EN"?"short-border":""}>
                        EN
                    </div>
                </div>
                <div className="language-wrapper" >
                    <div onClick={downloadLabel} className={currentLanguage==="FR"?"short-border":""}>
                        FR
                    </div>
                </div>
                <div className="language-wrapper">
                    <div onClick={downloadLabel} className={currentLanguage==="IT"?"short-border":""}>
                        IT
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageFooter