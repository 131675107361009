/*
Purpose: render telematic data into a table, if available.
otherwise the section is not shown at all
*/
// import external
import React, {useState} from "react";
import Moment from "moment"

// import styling
import "./_style.scss"

// import internal
import ButtonWithIcon from "../ButtonWithIcon";
import {useGetCarQuery, useUpdateCarMutation} from "../../redux/apiSlice/carApiSlice";
import AlertContainer from "../AlertContainer";
import FormModal from "../FormModal";
import FeatureIcon from "../FeatureIcon";
import DatePickerHandler from "../DatePickerHandler";
import WarningContainer from "../WarningContainer";

//import helper
import {thousandsSeparator} from "../../actions/formateNumbers.actions";
import {handleErrorMessage} from "../../actions/handleErrorMessage";

// import assets
import {ReactComponent as PlusIcon} from "../../assets/svg/icon-plus.svg";
import {ReactComponent as SpeedometerIcon} from "../../assets/svg/icon-speedometer.svg";




const CarTelematicDataTable = (props) => {

    // The default date for the date picker, is today's date
    const defaultDate = Moment(new Date()).format("YYYY-MM-DDT00:00:00Z")

    // What message / reason is shown
    const [warningMessage, setWarningMessage] = useState(null);

    // Whether the warning is fatal or not (if it is, the user can't continue)
    const [fatalWarning, setFatalWarning] = useState(false);

    // Whether the modal is shown or not
    const [showModal, setShowModal] = useState(false);

    // Once user selects a response (continue or cancel) this is set to either false or true
    let userContinue = null;

    const [updateTelematicData, {isLoading, isError, error}] = useUpdateCarMutation()

    const [telematicData, setTelematicData] = useState([])
    // Besides all the role required in the car details page, to change the tekematic data we need more

    // Stores data for input fields
    const [odometerData, setOdometerData] = useState({
        date: defaultDate,
        odometerValue: ""
    });

    const {
        data: vehicleData,
        isSuccess: isSuccessVehicleData,
    } = useGetCarQuery(props.data?.additionalCarInformation?.baseData?.vin);

    // Used in the body of the POST request
    const carData = {
        baseData: {
            carId: props.data.additionalCarInformation.baseData.carId,
            vin: props.data.additionalCarInformation.baseData.vin,
            status: props.data?.status,
            type: props.data.additionalCarInformation.baseData.type,
            brand: props.data.additionalCarInformation.baseData.brand,
        },
        additionalCarInformation: {
            baseData: {
                carId: props.data.additionalCarInformation.baseData.carId,
                vin: props.data.additionalCarInformation.baseData.vin,
                status: props.data?.status,
                type: props.data.additionalCarInformation.baseData.type,
                brand: props.data.additionalCarInformation.baseData.brand,
            },
            licensePlates: [...props.data.additionalCarInformation.licensePlates]
        },
        fleetInformation: {
            customerId: props.data.contractInformation?.contractOrganisation?.id
        }
    };



    //Necessary so that correct data is displayed when user switches tabs and comes back (and doesn't have to reload the page)
    if(isSuccessVehicleData && !telematicData?.length && props.data?.additionalCarInformation?.telematicData?.length){
            setTelematicData(vehicleData?.cars[0]?.additionalCarInformation?.telematicData)
    }



    // Called when user adds a new entry
    const onAddEntry = () => {
        //Check if entered data is "valid" (safe and makes sense logically) and if user hasn't already confirmed to continue
        if(!dataIsValid() && !userContinue){
            return;
        }

        userContinue = null;
        // Sends API call to add new data
        updateTelematicData({
            ...carData,
            additionalCarInformation: {
                ...carData.additionalCarInformation,
                telematicData: [
                    {
                        date: odometerData.date,
                        odometerValue: odometerData.odometerValue
                    }]
            }
        }).then((res) => {
            if(res.data){
                setTelematicData([
                    ...telematicData,
                    {
                        date: odometerData.date,
                        odometerValue: odometerData.odometerValue
                    }
                ])
            }
        })

        onCloseModal();
    }

    // Handles the closing of the modal (reset data, close modal, reset warning)
    const onCloseModal = () => {
        setOdometerData({
            date: defaultDate,
            odometerValue: ""
        })
        resetWarning();
        setShowModal(false);
    }

    // Resets everything related to the display of the warning message
    const resetWarning = () => {
        setWarningMessage(null);
        setFatalWarning(null);
        userContinue = null;
    }

    // Handles mileage value input field
    const handleChange = (e) => {
        resetWarning();
        setOdometerData({
            ...odometerData,
            [e.target.name]: e.target.value
        })
    }

    const handleMileageOnBlur=(e)=>{
        setOdometerData({
            ...odometerData,
            [e.target.name]: Number(e.target.value).toFixed(0)
        })
    }
    // Handles date input field
    const handleDateChange = (date) => {
        resetWarning();
        // If the date is null (only possible if user clicks "Reset" button, then don't try to format it)
        if(!date){
            setOdometerData({...odometerData, date: ""})
            return;
        }
        setOdometerData({...odometerData, date: Moment(date).format("YYYY-MM-DDT00:00:00Z")})
    }

    // Checks if the data entered by the user is valid (true: valid, false: invalid)
    const dataIsValid = () => {
        const mileageValue = odometerData.odometerValue;
        // Checks if Number(mileageValue) is NaN, so if what user entered cannot be converted to a number
        // eslint-disable-next-line no-self-compare
        if(mileageValue !== mileageValue){
            setWarningMessage(`${props.labels?.warningMessage?.mileageNotANumber}`);
            setFatalWarning(true);
            return false;
        }

        // If mileage counter value is zero
        if(Number(mileageValue) === 0){
            setWarningMessage(`${props.labels?.warningMessage?.negativeMileage}`);
            return false;
        }

        // If mileage counter value is negative
        if(mileageValue < 0){
            setWarningMessage(`${props.labels?.warningMessage?.negativeMileage}`);
            setFatalWarning(true);
            return false;
        }

        // Here we start to compare with older entries, so if there is no other data these checks are no longer relevant
        if(telematicData?.length === 0){
            return true;
        }

        const sortedEntries = tableContent = [].concat(telematicData)
            .sort((a, b) => a.date < b.date ? 1 : -1);
        let lastEntry = sortedEntries[0];
        // if mileage counter value is lower than last entry but date is more recent
        if(mileageValue <= lastEntry.odometerValue && Moment(odometerData.date).isAfter(lastEntry.date)){
            setWarningMessage(`${props.labels?.warningMessage?.newerDateButLowerMileage} `);
            return false;
        }

        else if(!Moment(odometerData.date).isAfter(Moment(lastEntry.date)) && mileageValue > lastEntry.odometerValue){
            setWarningMessage(`${props.labels?.warningMessage?.olderDateButHigherMileage}`);
            return false;

        }
        return true;
    }


    let tableContent = (
        <tr key="no data">
            <td className="mileage-counter-column">-</td>
            <td className="date-column">-</td>
        </tr>
    )

    if( telematicData?.length > 0){
        tableContent = [].concat(telematicData)
            .sort((a, b) => a.date < b.date ? 1 : -1)
            .map((item, i) => {
                    const c_name = i === 0 ? " highlighted" : "";
                    return (
                        <tr key={i}>
                            <td className={"mileage-data-column mileage-counter" + c_name}>{thousandsSeparator(item?.odometerValue)}</td>
                            <td className={"mileage-data-column date" + c_name}>{Moment(item?.date).format('DD.MM.YYYY')}</td>
                        </tr>
                    )
                }
            )
    }

    return (
        <>
            <FormModal show={showModal}
                       onClose={onCloseModal}
                        onOutsideClick={() => setShowModal(false)}
                       title={props.labels?.carsPage?.titles?.addMileageData}
                       featuredIcon={
                       <FeatureIcon>
                           <SpeedometerIcon style={{stroke:"#344054"}}/>
                       </FeatureIcon>
                   }>
                <div className="form-modal-content">
                    <div className="form-modal-input-container">
                        <div className="form-modal-input-title">
                            {props.labels?.carsPage?.mileageData?.mileageCounter}
                            <span className="required-label">
                                ({props.labels?.otherElements?.required})
                            </span>
                        </div>
                        <div className="form-modal-input-container-field">
                            <input className="input-field-primary" type="number" name="odometerValue" value={odometerData.odometerValue} onChange={e => handleChange(e)} onBlur={e=>handleMileageOnBlur(e)} style={{width:"100%"}}/>
                        </div>
                    </div>

                    <div className="form-modal-input-container">
                        <div className="form-modal-input-title">
                            {props.labels?.carsPage?.mileageData?.date}
                            <span className="required-label">
                                ({props.labels?.otherElements?.required})
                            </span>
                        </div>
                        <div className="form-modal-input-container-field">
                            <DatePickerHandler startDate={new Date()} onChange={handleDateChange}/>
                        </div>
                    </div>

                    <WarningContainer
                        hidden={!warningMessage}
                        title={props.labels?.warningMessage?.attention}
                    >
                        {warningMessage}
                    </WarningContainer>


                    {/* Modal Footer */}
                    <div className="form-modal-footer">
                        <button
                            onClick={onCloseModal}
                            className="btn btn-light"
                            style={{width: "45%"}}
                        >
                            {props.labels?.otherElements?.cancel}
                        </button>

                        {warningMessage ?

                            <button
                                onClick={() => {
                                    userContinue = true;
                                    onAddEntry();
                                }}
                                disabled={odometerData.date.length === 0 || odometerData.odometerValue.length === 0 || fatalWarning}
                                className="btn btn-danger"
                                style={{width: "45%"}}
                            >
                                {fatalWarning ? props.labels?.otherElements?.unavailable : props.labels?.otherElements?.save}
                            </button>

                            :

                            <button
                                onClick={() => {
                                    onAddEntry();
                                }}
                                disabled={odometerData.date.length === 0 || odometerData.odometerValue.length === 0}
                                className="btn btn-primary"
                                style={{width: "45%"}}
                            >
                                {props.labels?.otherElements?.save}
                            </button>
                        }
                    </div>
                </div>
            </FormModal>

            <AlertContainer alertStyle={"alert-warning"} show={isError}>{handleErrorMessage(error, props?.labels)}</AlertContainer>
            <div className="blank-card" style={{width:"575px"}}>
                <div className="section-card">
                    <div className="button-container" hidden={!props.allowEdit} style={{whiteSpace:"nowrap", marginBottom:"5px"}}>
                        {isLoading ?
                            <button className="btn btn-primary btn-disabled">
                                <div className="dot-falling-container">
                                    <div className="dot-falling"/>
                                </div>
                            </button>
                            :
                            <ButtonWithIcon text={props.labels?.otherElements?.addEntry}
                                            buttonStyle="btn-primary"
                                            clickFunc={() => {setShowModal(true)}}
                            >
                                <PlusIcon className="inner-icon"></PlusIcon>
                            </ButtonWithIcon>
                        }
                    </div>

                    <span>
                        <table className="mileage-data-table">
                            <thead>
                                <tr>
                                    <th className="mileage-counter-column">{props.labels?.carsPage?.mileageData?.mileageCounter}</th>
                                    <th className="date-column">{props.labels?.carsPage?.mileageData?.date}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent}
                            </tbody>
                        </table>
                    </span>

                </div>
            </div>
        </>

    )
}
export default CarTelematicDataTable