//import styling
import "./_style.scss"
import "react-datepicker/dist/react-datepicker.css";

//import assets
import {ReactComponent as ChevronLeft} from "../../assets/svg/icon-chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/svg/icon-chevron-right.svg";
import {ReactComponent as ChevronBottom} from "../../assets/svg/icon-chevron-bottom.svg";
import {ReactComponent as CalendarIcon} from "../../assets/svg/icon-calendar.svg";

//import external
import DatePicker, {registerLocale} from "react-datepicker";
import {forwardRef, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {enGB, de, fr, it} from 'date-fns/locale';
import Moment from "moment";
import PropTypes from "prop-types";

registerLocale('en', enGB); registerLocale('de', de); registerLocale('fr', fr); registerLocale('it', it);



const DatePickerHandler = (props) => {

    DatePickerHandler.propTypes = {
        startDate: PropTypes.object, // OPTIONAL, accept date object
        endDate: PropTypes.object, // OPTIONAL (and only if isRange is true), optional will be startDate + 14 days
        onChange: PropTypes.func, //Function that is called when date selections are modified
        disabled: PropTypes.bool,
        isRange: PropTypes.bool, // True if user should be able to select a range, false for single date selection,
        popperPlacement: PropTypes.string
    }
    const {popperPlacement} = {...props}

    //The initial startDate, used to reset the datepicker when the "Cancel" button is clicked
    // I removed the useState here, because 1. no function to update initialStartDate was defined
    // 2. initialStateDate is from props, when changed, a rerender is automatically triggered anyway.
    const initialStartDate = props.startDate || null;
    const initialEndDate = props.endDate || null;

    const [startDate, setStartDate] = useState(initialStartDate);
    //DISABLED: If not specified, use startDate (today if props.startDate is null) + 14 days, improves learnability on date range selection
    const [endDate, setEndDate] = useState(props.isRange ? initialEndDate : null);
    const [tempStartDate, setTempStartDate] = useState(initialStartDate);
    const [tempEndDate, setTempEndDate] = useState(props.isRange ? initialEndDate : null);

    // Ref for datepicker window, used to close it
    const calRef = useRef();

    //Language handling
    const currentLanguage = useSelector(state => state.labels.currentLanguage);
    const labels = useSelector(state => state.labels.currentLabels);

    // Used to control whether the bottom or top chevron is shown in the month/year picker
    const [showChevronTop, setShowChevronTop] = useState(false);

    // Month labels that are displayed in header of datepicker
    const months = [
        labels?.otherElements?.months?.january,
        labels?.otherElements?.months?.february,
        labels?.otherElements?.months?.march,
        labels?.otherElements?.months?.april,
        labels?.otherElements?.months?.may,
        labels?.otherElements?.months?.june,
        labels?.otherElements?.months?.july,
        labels?.otherElements?.months?.august,
        labels?.otherElements?.months?.september,
        labels?.otherElements?.months?.october,
        labels?.otherElements?.months?.november,
        labels?.otherElements?.months?.december
    ];

    // The input field which opens the DatePicker when clicked
    const DateInputField = forwardRef(({ value, onClick }, ref) => {
        // button html element does not work with isRange, startDate, endDate, spreading it out to remove React warning
        const {isRange, startDate, endDate, popperPlacement, ...rest} = {...props}
        const placeHolder = isRange ? labels?.otherElements?.dateRangePlaceholder : labels?.otherElements?.datePlaceholder;
        return (
            <button className="date-input-field" onClick={onClick} ref={ref} disabled={props.disabled} type="button" {...rest} >
                <CalendarIcon className="calendar-icon"/> {value || placeHolder}
            </button>
        )
    });


    // The MONTH YEAR ("Oktober 2023") header which opens up the month/year picker when clicked
    const MonthYearInput = forwardRef(({ value, onClick }, ref) => {
        const monthIndex = Number(value.split("/")[0]) - 1;
        const year = value.split("/")[1];
        const month = months[monthIndex];
        //Set month year picker to closed to show chevron bottom
        setShowChevronTop(false)
        return (
            <div className="month-year-input-field" onClick={onClick} ref={ref}>
                {month} {year}
                {showChevronTop ? <ChevronBottom className="chevron-bottom-icon flipped"/> : <ChevronBottom className="chevron-bottom-icon"/>}
            </div>
        )
    });

    // Handles data of input fields at top of datepicker
    const [inputFieldData, setInputFieldData] = useState({
        startDate: startDate,
        endDate: endDate
    })

    /**
     *  Handles the date changes performed on the input fields, first checks if they are valid dates,
     *  then checks if the DatePicker is single-date or range, and then either calls onChangeSingle or onChangeRange
     *  to perform the update of the startDate (and endDate) states and also to call the onChange props function
     **/
    const onChangeDateInput = (e) => {
        const {name, value} = e.target;
        setInputFieldData({...inputFieldData, [name]: value})

        try {
            const date = Moment(value, "YYYY-MM-DD").toDate();
            if(name === "startDate" && Moment(date).isValid()) {
                if(props.isRange){
                    onChangeRange([date, tempEndDate]);
                }
                else {
                    onChangeSingle(date)
                }
            }
            else if(name === "endDate" && Moment(date).isValid()) {
                onChangeRange([tempStartDate, date]);
            }
        }
        // If there was an exception when parsing the date (if it was invalid), do nothing
        catch (e) {
            return;
        }
    }

    // Contains the header of the Month/Year picker modal
    const MonthYearPickerHeader = ({date, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled}) => {
        // If this is shown, open the chevron icon
        setShowChevronTop(true)
        return (
            <div className="date-picker-header" id="month-year-picker">
                <span className="month-selection" style={{paddingBottom:0}}>
                    <button onClick={decreaseYear} disabled={prevYearButtonDisabled} className="date-picker-header-button" type="button">
                        <ChevronLeft/>
                    </button>
                    {date.getFullYear()}
                    <button onClick={increaseYear} disabled={nextYearButtonDisabled} className="date-picker-header-button" type="button">
                        <ChevronRight/>
                    </button>
                </span>
            </div>
            )

    }
    // Contains the entire header of the datepicker, so also the separate DatePicker instance which is used for Month/Year picker,
    // the two chevrons left/right, the clickable MONTH YEAR text, the input field(s) to enter the dates.
    const DatePickerHeader = ({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => (
        <div className="date-picker-header">
            <span className="month-selection">
                {/* Left Side Chevron*/}
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="date-picker-header-button" type="button">
                    <ChevronLeft/>
                </button>
                {/* Month/Year Picker, is rendered as the MONTH YEAR text and opens the Month/Year picker modal when clicked */}
                <DatePicker
                    selected={date}
                    value={date}
                    onChange={date => {
                        changeYear(date.getFullYear());
                        changeMonth(date.getMonth());
                    }}
                    showPopperArrow={false}
                    popperPlacement={popperPlacement || "auto-end"}
                    calendarClassName="month-year-container"
                    popperClassName="date-picker-popper"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    locale={currentLanguage.toLowerCase()}
                    customInput={<MonthYearInput/>}
                    renderCustomHeader={MonthYearPickerHeader}
                />
                {/* Right Side Chevron*/}
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="date-picker-header-button" type="button">
                    <ChevronRight/>
                </button>
            </span>
            <span className="date-selection">
                {/* Date Input Field */}
                <input
                    className="header-date-input-field"
                    name="startDate"
                    type="date"
                    onChange={e => {
                        onChangeDateInput(e);
                        if(Moment(e.target.value).isValid() && Moment(e.target.value).year().toString().length <= 4){
                            changeYear(Moment(e.target.value).year());
                            changeMonth(Moment(e.target.value).month());
                        }
                    }}
                    // Moment(null).format("yyyy-MM-dd") return "invalid date"
                    value={tempStartDate ? Moment(tempStartDate).format("YYYY-MM-DD") : null}
                />
                {props.isRange && <span className="header-date-input-connector"> - </span> }
                {props.isRange &&
                    <input
                        className="header-date-input-field"
                        name="endDate"
                        type="date"
                        onChange={e => onChangeDateInput(e)}
                        value={tempEndDate ? Moment(tempEndDate).format("YYYY-MM-DD") : null}
                    />
                }
            </span>
        </div>
    )

    // onChange function for range selection datepicker													   
    const onChangeRange = (dates) => {
        const [start, end] = dates;
        setTempStartDate(start);
        setTempEndDate(end);					   							 
    }

    // onChange function for single date selection datepicker
    const onChangeSingle = (date) => {
        setTempStartDate(date);
    }


    // handleApply function updates the final states and calls the onChange callback function when “Apply” is clicked
    const handleApply = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);

        if (props.onChange) {
            if (props.isRange) {
                props.onChange(tempStartDate, tempEndDate);
            } else {
                props.onChange(tempStartDate);
            }
        }
        calRef.current.setOpen(false);
    }

    // When the user clicks outside of the date picker, the date picker closes and dates are reset to the value before the date picker was opened
    const handleOutsideClick = () => {
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    }
    
    // Renders the Date Input Field which opens the entire Date Picker Modal when clicked
    return (
        <>
            {/*Is just shown as an Input Field, contains the modal*/}
            <DatePicker
                ref={calRef}
                selected={tempStartDate}
                startDate={props.isRange ? tempStartDate : startDate}
                endDate={props.isRange ? tempEndDate : null}
                onChange={props.isRange ? onChangeRange : onChangeSingle}
                locale={currentLanguage.toLowerCase()}
                renderCustomHeader={DatePickerHeader}
                calendarClassName="date-picker-container"
                showPopperArrow={false}
                dateFormat="dd.MM.yyyy"
                popperPlacement={popperPlacement || "auto"}
                shouldCloseOnSelect={false}
                selectsRange={props.isRange}
                focusSelectedMonth={true}
                customInput={<DateInputField/>}
                onClickOutside={() => handleOutsideClick()}
                fixedHeight
            >
                {/* Footer, Everything below the individual date bubbles*/}
                <div className="date-picker-footer">
                    {/* The "Reset" and "Apply" buttons in the footer */}
                    <div className="date-picker-footer-content">
                        <button
                            type="button"
                            id="date-picker-cancel-button"
                            className={"btn sm btn-light"}
                            style={{width: "100%"}}
                            onClick={() => {
                                calRef.current.clear();
                                // Date Picker should no longer close when "Reset" is clicked, as it requires confirmation
                                // using "Apply" first.
                                //calRef.current.setOpen(false);
                            }}
                        >
                            {labels?.otherElements?.reset}
                        </button>
                        <button
                            type="button"
                            id="date-picker-confirm-button"
                            className={"btn sm btn-primary"}
                            style={{width: "100%"}}
                            onClick={handleApply}
                        >
                            {labels?.otherElements?.apply}
                        </button>
                    </div>
                </div>
            </DatePicker>
        </>
    )
}

export default DatePickerHandler;