//import styling
import "./_style.scss";
// import external
import React, { useCallback, useMemo, useState } from "react";
import Moment from 'moment';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//assets
import { ReactComponent as MagnifierIcon } from "../../assets/svg/icon-magnifier.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/icon-download.svg";
// import internal
import GridHandler from "../GridHandler";
import KeyInfoCard from "../KeyInfoCard";
import { formatNumber } from "../../actions/formateNumbers.actions";
import ReportGenerationHandler from "./ReportGenerationHandler";
import GridTitle from "../GridHandler/GridTitle";
import { useGetCarInvoicesNoPosQuery } from "../../redux/apiSlice/invoiceApiSlice";
import LoadingComponent from "../LoadingComponent";
import { formatDateIfNotEmpty } from "../../actions/date.actions";
import { dateComparator, dateNowComparator } from "../GridHandler/dates.actions";
import { checkAgentLoginToken } from "../../actions/token.actions";
import { useLazyGetInvoiceQuery } from "../../redux/apiSlice/invoiceApiSlice";
import DatePickerHandler from "../DatePickerHandler";
import { convertToDate } from "../GridHandler/dates.actions";
import AlertContainer from "../AlertContainer";
import { triggerEvent } from "../../actions/eventTracking.actions";
import { Skeleton } from "../../components/Skeleton";
/**
 *
 * @param {*} props labels data
 * loads data for the cost overview and defines what to show
 * this component is used for the supplier invoice overview page and on the car details page
 * @returns
 */
const SupplierInvoicesOverview = (props) => {
    const selectedDates = useSelector(state => state.date.fleetCostDates);
    // Get correct default start date based on cached data or use first date of year
    const getDefaultStartDate = () => {
        // if there is a saved date in redux, use that
        if (selectedDates.startDate) {
            return convertToDate(selectedDates.startDate);
        }
        // if in redux it is saved that the user wants all time, use it
        else if (selectedDates.allTime) {
            return null;
        }
        // otherwise the default start date is the first date of the year
        else {
            return Moment().startOf('year').toDate(); // First day of year
        }
    };
    // Get correct default end date based on cached data or current date
    const getDefaultEndDate = () => {
        if (selectedDates.endDate) {
            return convertToDate(selectedDates.endDate);
        } else if (selectedDates.allTime) {
            return null;
        } else {
            return Moment().toDate(); // Today
        }
    };
    const defaultStartDate = getDefaultStartDate();
    const defaultEndDate = getDefaultEndDate();
    // The start and end date that is sent to the api, when set as empty string the api will return all data
    const [startDate, setStartDate] = useState(defaultStartDate ? Moment(convertToDate(defaultStartDate)).format("YYYY-MM-DD") : null);
    const [endDate, setEndDate] = useState(defaultEndDate ? Moment(convertToDate(defaultEndDate)).format("YYYY-MM-DD") : null);
    // Function for handling the data change in the DatePicker
    const handleRangeChange = (start, end) => {
        // As soon as the second date is selected, handle the change and fetch new data
        if (start && end) {
            setStartDate(Moment(start).format("YYYY-MM-DD"));
            setEndDate(Moment(end).format("YYYY-MM-DD"));
        }
        // This is executed when the user resets the dates
        else if (!start && !end) {
            setStartDate(null);
            setEndDate(null);
        }
    };
    let [triggerDocumentDownload] = useLazyGetInvoiceQuery();

    //Using the data from the api call in downloadDocument(), we create a link and click it to download the file
    const openDownloadLink = useCallback((file, documentName) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + file;
        a.download = documentName;
        a.click();
    }, [])
    //When user clicks on a download button, this function is called to trigger the api call for that invoice id

    const downloadDocument = useCallback(async (documentId, documentName) => {
        let result = await triggerDocumentDownload(documentId, { preferCacheValue: true });
        if (result.isSuccess && result.data?.documentFile) {
            // Check if there are documents available before attempting to download
            openDownloadLink(result.data.documentFile.file, documentName);
            // add ga 4
            triggerEvent('file_download', 'click', 'success', 'pdf', 'download_button');
        } else {
            // push event for ga4
            triggerEvent('file_download', 'click', 'error', 'pdf', 'grid_row_download_button');
        }
    }, [openDownloadLink, triggerDocumentDownload]);
    // Get car invoices from API
    const {
        data: carInvoices,
        isFetching,
        error,
    } = useGetCarInvoicesNoPosQuery({
        vin: props.vin,
        fromDate: startDate,
        toDate: endDate,
    });
    const {
        data: m2dcarInvoices,
        isFetching: mtdisFetching,
        error: mtdError,
    } = useGetCarInvoicesNoPosQuery({
        vin: props.vin,
        fromDate: Moment().add(-60, "day").format("YYYY-MM-DD"),
        toDate: Moment().add(1, "day").format("YYYY-MM-DD"),
    });
    // additional columns for agent
    const addColDefsForAgent = [
        {
            headerName: "AAX ID",
            colId: "aaxInvoiceId",
            valueGetter: (params) => params.data.id,
        },
    ];

    // define grid columns
    let columnDefs = useMemo(() => [
        {
            cellRenderer: function (params) {
                const invoiceDocuments = params.data.invoiceDocuments;
                if (invoiceDocuments && invoiceDocuments?.length > 0 && invoiceDocuments[0].visibleInCSC === true) {
                    return (
                        /* Since it's clear here that at least one invoiceDocument is available, we don't need to secure the get id of the invoiceDocuments */
                        <span onClick={() => downloadDocument(invoiceDocuments[0].id, params.data.invoiceNumber + "_" + params.data.invoicingPartyText + ".pdf")} style={{ cursor: "pointer" }} id={invoiceDocuments[0].id} data-cy={'invoice-download-icon'}>
                            <DownloadIcon className="grid-icon" />
                        </span>
                    );
                } else {
                    // No documents available, do not render the download icon
                    return (
                        <span style={{ cursor: "not-allowed" }}>
                            <DownloadIcon className="grid-icon" style={{ opacity: 0 }} />
                        </span>
                    );
                }
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            suppressMenu: true,
            colId: "icon",
            suppressMovable: true,
            // there used to a width param here, removed because when it is there, the column width changes after clicking
            // on the download button
            // width:65
        },
        {
            // Link Icon to invoice detail page
            cellRenderer: function (params) {
                return (
                    <Link to={"/supplierInvoices/" + params.data.id}>
                        <MagnifierIcon alt="open row details icon" className="grid-icon" />
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            suppressMenu: true,
            colId: "icon",
            suppressMovable: true,
            // there used to a width param here, removed because when it is there, the column width changes after clicking
            // on the download button
            // width:65
        },
        {
            headerName: props.labels?.supplierInvoicesPage?.supplierInvoicesInfo?.invoiceNumber,
            colId: "invoiceNumber",
            valueGetter: (params) => params.data.invoiceNumber,
        },
        {
            headerName: props.labels?.supplierInvoicesPage?.supplierInvoicesInfo?.invoiceDate,
            colId: "invoiceDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data?.invoiceDate);
            },
            filter: 'agDateColumnFilter',
            // sorting related
            sort: 'desc',
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.supplierInvoicesPage?.supplierInvoicesInfo?.invoicingParty,
            colId: "invoicingParty",
            valueGetter: function (params) {
                return params.data?.invoicingPartyText;
            }
        },
        {
            headerName: props.labels?.costPage?.costInfo?.totalPriceWithVat,
            colId: "totalPriceWithVat",
            valueGetter: function (params) {
                return params.data.totalAmountInclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.costPage?.costInfo?.totalPriceNoVat,
            colId: "totalPriceNoVat",
            valueGetter: function (params) {
                return params.data?.totalAmountExclTax;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
        {
            headerName: props.labels?.costPage?.costInfo?.VAT,
            colId: "VAT",
            valueGetter: function (params) {
                return params.data.taxAmount;
            },
            valueFormatter: function (params) {
                return formatNumber(params.value);
            },
            type: 'rightAligned',
            filter: 'agNumberColumnFilter'
        },
    ], [downloadDocument, props.labels]);

    let gridTitle = useMemo(() => (
        <GridTitle
            title={props.labels?.supplierInvoicesPage?.titles?.gridTitle}
            subtitle={props.labels?.supplierInvoicesPage?.titles?.gridSubtitle}
            badgeText={carInvoices?.length + " " + props.labels?.supplierInvoicesPage?.supplierInvoicesInfo?.invoices}
        />
    ), [props.labels, carInvoices?.length]);


    let content;
    let cardContent;
    const pastInvoices = m2dcarInvoices?.filter(invoice => Moment(invoice.invoiceDate).isBefore(Moment(), 'day')) || [];
    let invoicesThisMonth = pastInvoices.filter(invoice => Moment(invoice.invoiceDate).isSame(Moment(), 'month')).length;
    let invoicesPastThirtyDays = pastInvoices.filter(invoice => Moment(invoice.invoiceDate).isSameOrAfter(Moment().subtract(30, 'days'))).length;
    const currentMonth = Moment().format("MMMM").toLowerCase();
    const currentMonthTranslated = props.labels?.otherElements?.months?.[currentMonth];
    let isAgentLogin = checkAgentLoginToken();
    if (isAgentLogin) {
        columnDefs = columnDefs.concat(addColDefsForAgent);
    }
    if (isFetching) {
        content = <LoadingComponent show />
    } else if (error || !carInvoices?.length) {
        // If we get an error (or if no invoices), we manually set the card values to 0, because otherwise it will use
        // the previous correctly loaded data, which is not correct
        // Yue: comment this out. The cards are no longer using the same api call. 
        // invoicesThisMonth = 0;
        // invoicesPastThirtyDays = 0;
        // Return error message
        content = (
            <AlertContainer key="alertContainerNoDataForTimeFrame" show={true} alertStyle={"alert-warning"}>
                {props.labels?.errorMessage?.noDataForTimeFrame}
            </AlertContainer>
        );
    } else {
        // Return Grid with data
        content = (
            <>
                <GridHandler
                    gridTitle={gridTitle}
                    gridName={`upto_supplier_invoices_overview_${props.vin || "fleet"}`}
                    data={carInvoices}
                    columnDefs={columnDefs}
                    downloadable={true}
                    fileName={`upto_${props.labels?.supplierInvoicesPage?.titles?.supplierInvoicesOverview.toLowerCase()}_${props.vin || "fleet"}`}
                    downloadTextCsv={props.labels?.otherElements?.downloadCsv}
                    downloadTextExcel={props.labels?.otherElements?.downloadExcel}
                />
            </>
        );
    }
    if (mtdisFetching) {
        cardContent = <Skeleton height="25px" width="100%" />
    } else if (mtdError) {
        cardContent = <></>
    } else {
        cardContent = <>
            <KeyInfoCard
                title={props.labels?.supplierInvoicesPage?.keyNumbers?.numberOfInvoices + " (" + currentMonthTranslated + ")"}
                number={invoicesThisMonth}
                cardStyle={"card-neutral-light"}
            />
            <KeyInfoCard
                title={props.labels?.supplierInvoicesPage?.keyNumbers?.numberOfInvoicesLast30Days}
                number={invoicesPastThirtyDays}
                cardStyle={"card-neutral-light"}
            />
        </>
    }
    return (
        <div className="section-container-content">
            <div className="fleet-header">
                <h1>{props.title}</h1>
                {/*the report generation button is only needed on the supplier invoice overview page*/}
                {/* Yue: I moved the button here from below datepicker because the loading of lieferant invoice api should not affect the button  */}
                {!props.vin ? <ReportGenerationHandler style={{ width: "fit-content", justifySelf: "end" }} labels={props.labels?.supplierInvoicesPage?.reportGeneration} /> : <></>}
            </div>
            <div className="cost-overview-controls">
                <span style={{ width: "275px" }}>
                    <DatePickerHandler isRange={true} onChange={handleRangeChange} startDate={convertToDate(startDate)}
                        endDate={convertToDate(endDate)} />
                </span>
            </div>
            <div className="supplier-invoices-content">
                <div className="number-cards-container">
                    {cardContent}
                </div>
                {content}

            </div>
        </div>
    );
};
export default SupplierInvoicesOverview;
