// import react/redux component
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
// import internal styling
import "./_style.scss"
import {apiCallAgentLogin} from "../actions/apicall.actions";
import {setTokenAndSave} from "../redux/authSlice/authSlice";
import FallbackPage from "../pages/FallbackPage";
import {saveAgentLoginToken} from "../actions/token.actions";
import {generateEncryptedString} from "../actions/encrypt.actions";
import {triggerLoginEvent} from "../actions/eventTracking.actions";


const AgentLogin = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getUrlParams = (location) =>
        location.search
            .slice(1)
            .split("&")
            .map((p) => p.split("="))
            .reduce((obj, pair) => {
                const [key, value] = pair.map(decodeURIComponent);
                return {...obj, [key]: value};
            }, {});
    const { hash, customer, user } = getUrlParams(location);

    useEffect(() => {
        apiCallAgentLogin({ hash, customer, user })
            .then((response) => {
                dispatch(setTokenAndSave({access_token:response.access_token, refresh_token:response.refresh_token}));

                //Generate encrypted string and save it to local storage
                saveAgentLoginToken(generateEncryptedString());

                // Trigger GTM Event: User logged in using AgentLogin
                triggerLoginEvent('agentLogin', response.access_token, true)

                navigate("/")
            }).catch(
                (error)=>{
                    // Trigger GTM Event: User failed to login using AgentLogin
                    triggerLoginEvent('agentLogin', null, false)
                    navigate("/login")
                }
            );
        },[customer, dispatch, hash, navigate, user]
    )
    return <FallbackPage></FallbackPage>
}

export default AgentLogin