// This file is created based on the idea to use redux store to persist and refresh token
// it first defines a basequery that by default include the access_token in redux to facilitate api request for cars or
// user data

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {addAbortedAPI, logOut, setTokenAndSave} from "../authSlice/authSlice"
import {apiCallRefresh2faToken, apiCallRefreshToken} from "../../actions/apicall.actions";
import {determineLanguage} from "../../actions/language.actions"
import {STORAGE_CONST} from "../../actions/constants/storage.constants";
import {checkAgentLoginToken} from "../../actions/token.actions";
import Cookies from "js-cookie";


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, {endpoint,getState }) => {
        // Load access token from cookies
        const access_token = Cookies.get(STORAGE_CONST.ACCESS_TOKEN)

        if (access_token) {
            headers.set("Authorization", `Bearer ${access_token}`);
            headers.set("Host", process.env.REACT_APP_BACKEND_BASE_URL);
            headers.set("Cookie", process.env.REACT_APP_SERVER_COOKIE);
            headers.set("Connection", "keep-alive");
        }
        // the header we can set for individual endpoints get overwritten here.
        // therefore better have a conditional check here instead of setting headers in each endpoint.
        if (endpoint!=="getLabels"){
            headers.set("Accept-Language", determineLanguage());
        }
        return headers
    }
})

let refreshTokenPromise = null;

// automatically refresh token with the refresh token used from redux
const baseQueryWithReAuth = async (args, api, extraOptions) => {
    // Load which APIs have been aborted (because they returned 500)
    const abortedAPI = api.getState().auth.isAPIAborted;
    // Get an identifier for this api call, either as string or stringified object
    let apiIdentifier = JSON.stringify({...args});
    if (typeof(args) === "string") {
        apiIdentifier = args;
    }
    // Check if this API has been aborted
    const isAborted = !!abortedAPI[apiIdentifier];

    // If answer to above is yes, do not execute
    if (isAborted){
        api.abort();
        return
    }
    // Otherwise execute
    let result = await baseQuery(args, api, extraOptions);

    // If API returns 500, we remember not to execute it (we add it to aborted list)
    if (result?.meta?.response?.status === 500 ) {
        api.dispatch(addAbortedAPI(apiIdentifier))
        return result
    }
    else if (result?.meta?.response?.status !== 401 ) {
        // If no error or error different to 401, return result.
        return result;
    }

    // Error is 401, so we need to refresh access token.
    // infoFor2FA tells me which refresh endpoint to use
    const infoFor2FA = localStorage.getItem(STORAGE_CONST.INFO_4_REFRESH_ENDPOINT);

    // Load refresh token from cookies
    const refresh_token = Cookies.get(STORAGE_CONST.REFRESH_TOKEN)

    if (!infoFor2FA || !refresh_token) {
        // No 2FA info or refresh token -> We go back to login screen.
        api.dispatch(logOut())
        return result
    }
    const infoObject = JSON.parse(infoFor2FA)
    const username = infoObject.username
    const hasNo2faRole = infoObject.withNo2faRole
    const isAgentLogin= checkAgentLoginToken()
    // we use old endpoint only when the user is not agent and has the kein2FA role
    const useOldEndpoint = !isAgentLogin && hasNo2faRole
    if (hasNo2faRole === undefined || username === undefined) {
        // No 2FA info -> We go back to login screen.
        api.dispatch(logOut());
        return result;
    }
    try {
        if (!refreshTokenPromise) {
            refreshTokenPromise = useOldEndpoint ? apiCallRefreshToken(refresh_token) : apiCallRefresh2faToken(refresh_token, username);
        }

        const refreshResult = await refreshTokenPromise;

        refreshTokenPromise = null; // Reset the promise after it's done
        if (!refreshResult) {
            api.dispatch(logOut());
            return result;
        }
        // store the new token to redux and local storage. Next time when AT expires, we would use
        // the new RT to generate AT
        // retry the original query with new access token
        api.dispatch(setTokenAndSave({...refreshResult}));
        result = await baseQuery(args, api, extraOptions);
        // when the result is error with code 401, it does not trigger the try catch
        // clause. Therefore, we are adding the condition below to log out. If after reauth, we still get 401
        // error. The user should be logged out. Otherwise, we will be stuck in a loop.
        if (result.error?.status === 401) {
            api.dispatch(logOut())
        }
    }catch (e){
        refreshTokenPromise = null;
        api.dispatch(logOut());
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Invoice', 'User', 'Car','Cars','Driver', "User", "Label", "FuelCards", "Bills", "Damages","InvoiceDetails", "FleetCostsByCostType", "FleetCostsByCar","TwoFactor","Documents", "ReportStatus","CarInvoicesNoPos"],
    endpoints: builder => ({})
});