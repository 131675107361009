// endpoint to get all car related information
import {apiSlice} from "./apiSlice"
import {API_FAMILY_CONST} from "../../actions/constants/api.constants";
import {dataCrossTabTransform} from "../../actions/crossTab.actions";

export const carsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCars: builder.query({
            query: () => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES,
                method: 'GET',
                params: {
                    loadContractData: true,
                },
                //caching for 5 min
                keepUnusedDataFor: 600,
            }),
            providesTags: ['Cars'],
            transformResponse: res => {
                return dataCrossTabTransform(res);
            },
        }),
        getCar: builder.query({
            query: (vinNr) => ({
                url: API_FAMILY_CONST.CAR_BY_VALUES,
                method: 'GET',
                params: {
                    vin: vinNr,
                    loadContractData: true
                }
            }),
            providesTags: (result, error, arg) =>{
                return result
                    ? [{type:"Car", id:arg}]
                    : []
            },
            transformResponse: res => {
                return dataCrossTabTransform(res);
            },
        }),
        updateCar: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.UPDATE_CAR,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : [{type:"Car", id: result.vin}, {type:"Cars", id: result.vin}]
            }
        }),
        createCar: builder.mutation({
            query(data) {
                return {
                    url: API_FAMILY_CONST.UPDATE_CAR,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags:  (result, error) =>{
                return error ? [] : ['Cars']
            }
        })
    })
})

export const {
    useGetCarsQuery,
    useGetCarQuery,
    useLazyGetCarQuery,
    useUpdateCarMutation,
    useCreateCarMutation,
    usePrefetch
} = carsApiSlice

// The hook below is no longer needed. Nevertheless, it works and is left as an example.
//export const useGetCarQueryState = apiSlice.endpoints.getCar.useQueryState;