import {AgChartsReact} from "ag-charts-react";
import {formatNumber} from "../../../actions/formateNumbers.actions";
import {sanitizeValues} from "../../../actions/sanitize";

const BillPositionsByType = (props) => {
    const {data, labels} = props;

    const total = data.reduce((acc, curr) => acc + curr.amount, 0)

    const options = {
        data,
        padding: {
        },
        series: [
            {
                type: 'donut',
                calloutLabelKey: 'description',
                fillOpacity: 1,
                strokeWidth: 0,
                angleKey: 'amount',
                sectorLabelKey: 'amount',
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    enabled: false,
                },
                fills: [
                    '#00006D',
                    '#00008F',
                    '#1F1F9C',
                    '#3D3DAA',
                    '#5C5CB7',
                    '#241D0C',
                    '#4B3E1B',
                    '#6E5C28',
                    '#8A7333',
                    '#A79666',
                    '#015A66',
                    '#41949F',
                ],
                innerRadiusRatio: 0.5,
                innerLabels: [
                    {
                        text: labels?.billsPage?.billsInfo?.totalExclTax,
                        fontSize: 12,
                        color: '#475467',
                        fontFamily: 'Space Grotesk'

                    },
                    {
                        text: formatNumber(total),
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: '#101828',
                        fontFamily: 'Space Grotesk'
                    },
                ],
                highlightStyle: {
                    item: {
                        fillOpacity: 0,
                        stroke: '#ffffff',
                        strokeWidth: 1
                    },
                },
                tooltip: {
                    renderer: function (params) {
                        params = sanitizeValues(params);
                        return {
                            content: `
                            <div class="type-tooltip"> 
                                 <div class="type-tooltip-title">
                                    <span class="marker" style="background-color:${params?.color}"></span>
                                    ${params?.datum.description}
                                 </div> 
                                <div class="type-tooltip-item">CHF ${formatNumber(params.datum.amount)}</div> 
                            </div>`
                        }
                    }
                }
            }
        ],
        legend: {
            position: 'right',
            item: {
                marker: {
                    shape: 'circle',
                    strokeWidth: 0
                },
                label: {
                    color: '#475467',
                    fontFamily: 'Space Grotesk'

                }
            }
        }
    }
    return <AgChartsReact options={options} />;

}

export default BillPositionsByType;