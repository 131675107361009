import "./_style.scss"
import React, {useEffect} from "react";

import {ReactComponent as CheckIcon} from "../../../assets/svg/icon-check.svg";




const Combobox = ({ children, onSelect, ...props }) => {
    // Stores search input value
    const [search, setSearch] = React.useState("");
    // Whether the "select" list is open
    const [open, setOpen] = React.useState(false);
    // The selected item (usually the ID)
    const [selected, setSelected] = React.useState(props.selected || null);

    // Refs to the input and list elements
    const inputRef = React.useRef(null);
    const listRef = React.useRef(null);

    // Close the list when the user scrolls outside of combobox (and combobox list)
    useEffect(() => {
        /*
        if (open && inputRef.current && listRef.current) {
            // Position the ComboboxList below the input (otherwise can look weird sometimes)
            const inputRect = inputRef.current.getBoundingClientRect();
            listRef.current.style.top = `${inputRect.top -16}px`;

        }
         */


        const handleScroll = (event) => {
            if (open) {
                const target = event.target;

                // Check if the scroll event is outside both the input and the list
                const isOutside = !inputRef.current.contains(target) &&
                    !(listRef.current && listRef.current.contains(target));

                // Close the combobox if the scroll happened outside
                if (isOutside) {
                    setOpen(false);
                }
            }
        };

        // Listen for scroll events on the document, capturing phase to catch all scrolls
        document.addEventListener('scroll', handleScroll, true);

        // Cleanup
        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };
    }, [open, inputRef, listRef]);


    // When an item is selected, close the list and call the onSelect callback
    const handleSelect = (value) => {
        // If user unselected, we also reset the search field text
        if (value === null) {
            if (inputRef?.current) inputRef.current.value = "";
            setSearch("")
        }
        setSelected(value);
        setOpen(false);
        onSelect(value)

    }

    // Inject / Pass props to children
    const injectProps = (child) => {
        if (child.type.displayName === 'ComboboxList') {
            return React.cloneElement(child, {
                setSearch,
                search,
                open,
                selected,
                setSelected,
                onSelect: handleSelect,
                ref: listRef,
            })
        }
        if (child.type.displayName === 'ComboboxInput') {

            return React.cloneElement(child, {
                search,
                setSearch,
                open,
                setOpen,
                selected,
                setSelected,
                ref: inputRef,
            })
        }
    }

    let {selectedValue, ...rest}=props

    return (
            <div className="combobox" {...rest}>
                {React.Children.map(children, injectProps)}
            </div>
    )
}


const ComboboxInput = React.forwardRef((props, ref) => {
    const inputRef = ref;
    let {setSearch,setOpen,selectedValue,...rest}=props
    // If an item is selected, set the input field (and search state) value to the "selected" value
    // (we don't actually set it to the selected value, because we use the item ID as value, so we instead have a
    // "selectedValue" prop that we can use to set the input field value (e.g. the name of the selected item))
    useEffect(() =>{
        if (props.selected) {
            inputRef.current.value = selectedValue || props.selected;
            props.setSearch(selectedValue || props.selected);
        }
        // eslint-disable-next-line
    }, [props.selected])

    return (
        <input {...rest} ref={inputRef} className="input-field-form"
            onChange={(e) => {
                setSearch(e.target.value);
                setOpen(true);
            }}
            onFocus={() => setOpen(true)}
            // Without onBlur timeout the list will close before the click event on the item is triggered
            onBlur={() => setTimeout(() => setOpen(false), 250)}
            style={{fontWeight: props.search === selectedValue ? 'bold' : 'normal'}}

        />
    )
})

ComboboxInput.displayName = 'ComboboxInput';

const ComboboxList = React.forwardRef(({ children, ...props }, ref) => {
    const listRef = ref;

    let {setSelected, ...rest}=props

    if (!props.open) return null;

    // Get filtered children based on search input
    const filteredChildren = React.Children.toArray(children).filter((child) => {
        if (props.search) {
            // If child props is null or undefined, return empty string
            if (!child?.props?.children && child?.props?.children !== 0) return "";
            // If child props children is string, convert it to lower case
            else if (typeof child?.props?.children === 'string') {
                return child?.props?.children?.toLowerCase().includes(String(props?.search).toLowerCase());
            }
            else if (typeof child?.props?.children === 'number') {
                return String(child?.props?.children).toLowerCase().includes(String(props?.search).toLowerCase());
            }
            // If child props children is array, join it to a string
            const lowerCaseChildren = child?.props?.children?.map((child) => child.toLowerCase());
            const childrenString = lowerCaseChildren.join("");

            return childrenString.includes(String(props.search).toLowerCase());
        }
        return true;
    })

    // If no results, show "No results found" message
    if (filteredChildren.length === 0)  {
        return (
            <div className="combobox-list" ref={listRef}>
                {props.noResults || "No results found"}
            </div>
        )
    }

    const injectProps = (child) => {

        if (child.type.displayName === 'ComboboxItem') {
            return React.cloneElement(child, {
                onSelect: props.onSelect,
                selected: props.selected,
                setSelected: setSelected,
            })
        }
    }


    return (
        <div {...rest} className="combobox-list"  ref={listRef}>
            {React.Children.map(filteredChildren, injectProps)}
        </div>
    )
})

ComboboxList.displayName = 'ComboboxList';

const ComboboxItem = ({ children, ...props }) => {
    let {setSelected,onSelect, ...rest}=props
    // If the item is selected, add a checkmark icon
    const isSelected = (props?.selected === props?.value && !props?.disabled && props?.selected !== null);
    return (
        <div className="combobox-item"
             {...rest}
             data-disabled={props?.disabled}
             data-selected={isSelected}
             onClick={() => {
                 // If the item is disabled, do nothing
                if (props.disabled) return;
                // If the item is already selected, deselect it
                if (props.selected === props.value) {
                    props.setSelected(null);
                    onSelect(null);
                    return;
                }
                // Otherwise, select it
                onSelect(props.value);


            }}
        >
            {children}

            {isSelected && <CheckIcon className="selected-check" />}


        </div>
    )
}

ComboboxItem.displayName = 'ComboboxItem';



export { Combobox, ComboboxInput, ComboboxList, ComboboxItem };



