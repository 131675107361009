// import styling
import "./_style.scss"

// import external
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import internal
import LoadingComponent from "../../components/LoadingComponent";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import { useGetDamageQuery, useUpdateDamageMutation } from "../../redux/apiSlice/damagesApiSlice";

// import helper
import { formatDateHtmlFormat, formatDateIfNotEmpty, formatDateToDatetimeIfNotEmpty } from "../../actions/date.actions";
import { formatNumber } from "../../actions/formateNumbers.actions";
import { createErrorMessage } from "../../components/AlertContainer/alert.utils";
import { damageMap, getDamageType, getCountryName } from "../../actions/magicNumber.actions";

// import assets
import { ReactComponent as CarImage } from "../../assets/svg/big-car.svg";
import { ReactComponent as PersonIcon } from "../../assets/svg/icon-person.svg";
import { useGetUserQuery } from "../../redux/apiSlice/userApiSlice";
import { ROLE_CONST } from "../../actions/constants/role.constants";
import EditButtonGroup from "../../components/EditButtonGroup";
import { setNestedPropertyValue } from "../../actions/modifyJsObject.actions";

import SearchForCar from "./SearchForCar";
import { useVariableValue } from "@devcycle/react-client-sdk";


export const FormInputItem = (props) => {
    const { name, value, type, handleChange, disabled } = props
    return (
        <input className="input-field-primary-damage-details" type={type || "text"}
            name={name}
            value={value}
            onChange={e => handleChange(e)}
            style={{ width: "100%", maxWidth: "200px" }}
            disabled={disabled}
            {...props}
        />
    )
}

// A modified version of the blank card with a title at the top
const InfoCard = (props) => {
    const { title, children } = props;
    return (
        <div className="blank-card info-card" {...props} title="">
            <div className="title" hidden={!title}>
                {title}
            </div>
            <div className="info-card-content">
                {children}
            </div>
        </div>
    )
}

// An information item that consists of a small label at the bottom and a larger value at the top
export const InformationItem = (props) => {
    const { label, children, valueStyle } = props;
    return (
        <div className="info-item" {...props} >
            <div className="label">
                {label}
            </div>

            <div className="value" style={valueStyle}>
                {children || "-"}
            </div>
        </div>
    )

}


const DamageDetailsPage = () => {
    //----------------------states-----------------------------//
    const [isEditing, setIsEditing] = useState(false)
    const [damageData, setDamageData] = useState({})
    // this is needed because we need the car id to update damage, but this field is not displayed
    const [validSelectedVehicle, setValidSelectedVehicle] = useState({})
    //----------------------api-----------------------------//
    // get the labels
    const labels = useSelector(state => state.labels.currentLabels)
    // get user role info
    const {
        data: userData,
    } = useGetUserQuery();
    // get damage id from url
    const param = useParams()
    const damageId = param.id
    // get damage data for the damage id
    const { data, isLoading, isSuccess, error } = useGetDamageQuery(damageId)
    const [updateDamageData, { isLoading: isUpdateLoading, isError: isUpdateError }] = useUpdateDamageMutation()


    //----------------------auth-----------------------------//
    // check if the current user is agent
    const isAgent = useSelector(state => state.auth.isAgentLogin) || false;
    const hasDamageRole = userData?.roles?.some(role => role.id === ROLE_CONST.DAMAGE)
    const damagePageKey = 'damage-page'
    const isShowDamagePage = useVariableValue(damagePageKey, false)

    let allowEdit = hasDamageRole && (isShowDamagePage || isAgent)

    //----------------------functions-----------------------//
    const handleChange = (e) => {
        const { name, value } = e.target
        setDamageData(prevState => {
            let newObject = JSON.parse(JSON.stringify(damageData))
            // language , status and country id need to be converted to int
            if ((name === "thirdPartyLanguage" || name === "amsDamageStatus" || name === "damageLocation.country.id") && typeof (value) === "string") {
                let id = parseInt(value, 10)
                setNestedPropertyValue(newObject, name, id);
            } else {
                setNestedPropertyValue(newObject, name, value);
            }
            return (newObject)
        })
    }


    const getDamageStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return (
                    <span className="badge-negative">
                        {labels?.damagesPage?.damageInfo?.open}
                    </span>
                )
            case 2:
                return (
                    <span className="badge-positive">
                        {labels?.damagesPage?.damageInfo?.closed}
                    </span>
                )
            default:
                return (
                    <span className="badge-grey">
                        {labels?.damagesPage?.damageInfo?.unknown}
                    </span>
                )
        }
    }

    const getLanguage = (languageId) => {
        switch (languageId) {
            case 1:
                return "Deutsch"
            case 2:
                return "English"
            case 3:
                return "Français"
            case 4:
                return "Italiano"
            default:
                return "-"
        }
    }
    // there are some fields we need to do a reformatting so that api will accept the value
    const handleSave = () => {
        setIsEditing(false)
        let newObject = JSON.parse(JSON.stringify(damageData))
        // reformat dates
        if (newObject.damageDate) {
            let damageDate = formatDateToDatetimeIfNotEmpty(newObject.damageDate)
            newObject.damageDate = damageDate
        }

        if (newObject.thirdPartyBirthdate) {
            let bDay = formatDateToDatetimeIfNotEmpty(newObject.thirdPartyBirthdate)
            newObject.thirdPartyBirthdate = bDay
        }
        // if damageLocation is newly defined, it requires a country
        if (newObject.damageLocation && !newObject.damageLocation?.country?.id && !data.damageLocation) {
            // 200 is switzerland
            let countryCode = 200
            newObject.damageLocation.country = {
                "id": countryCode
            }
        }
        // if 3rd person location is newly defined, it requires a country
        if (newObject.thirdPartyAddress && !newObject.thirdPartyAddress?.country?.id && !data.thirdPartyAddress) {
            // 200 is switzerland
            let countryCode = 200
            newObject.thirdPartyAddress.country = {
                "id": countryCode
            }
        }
        // if email is newly defined, it requires type
        if (newObject.thirdPartyMail?.email && !data.thirdPartyMail?.emailType) {
            // 4 is email
            let emailType = 4
            newObject.thirdPartyMail.emailType =
            {
                "id": emailType
            }
        }

        if (validSelectedVehicle?.additionalCarInformation?.baseData?.carId) {
            newObject.vehicleAax = {
                "carId": validSelectedVehicle?.additionalCarInformation?.baseData?.carId
            }
        }
        updateDamageData(newObject)
    }

    const handleEnableEdit = () => {
        setIsEditing(true)
    }

    // if the update is unsuccesful, reset the damageData State back to original data
    useEffect(() => {
        if (isUpdateError) {
            setIsEditing(true)
        }
    }, [isUpdateError])

    const cannotSaveError = createErrorMessage(error, "", "h2", labels, "canNotLoad")
    let content;

    if (isLoading) {
        content = <LoadingComponent show />
    } else if (isSuccess) {
        if (damageData && Object.keys(damageData).length === 0) {
            setDamageData(data)
        }
        // Create an array of fields to be displayed in the third party card
        const thirdPartyFields = [
            {
                label: labels?.driverPage?.driverInfo?.firstName,
                value: damageData?.thirdPartyFirstName,
                name: "thirdPartyFirstName"
            },
            {
                label: labels?.driverPage?.driverInfo?.familyName,
                value: damageData?.thirdPartyLastName,
                name: "thirdPartyLastName"
            },
            {
                label: labels?.driverPage?.driverInfo?.birthday,
                value: damageData?.thirdPartyBirthdate,
                name: "thirdPartyBirthdate",
                type: "date"
            },
            {
                label: labels?.accountPage?.accountInfo?.email,
                value: damageData?.thirdPartyMail?.email,
                name: "thirdPartyMail.email",
                type: "email"
            },
            {
                label: labels?.driverPage?.driverInfo?.phone,
                value: damageData?.thirdPartyPhone?.cli,
                name: "thirdPartyPhone.cli"
            },
            {
                label: labels?.driverPage?.driverInfo?.street,
                value: damageData?.thirdPartyAddress?.street,
                name: "thirdPartyAddress.street"
            },
            {
                label: labels?.driverPage?.driverInfo?.houseNumber,
                value: damageData?.thirdPartyAddress?.houseNumber,
                name: "thirdPartyAddress.houseNumber"
            },
            {
                label: labels?.driverPage?.driverInfo?.city,
                value: damageData?.thirdPartyAddress?.city,
                name: "thirdPartyAddress.city"
            },
            {
                label: labels?.driverPage?.driverInfo?.zip,
                value: damageData?.thirdPartyAddress?.zip,
                name: "thirdPartyAddress.zip"
            },
            {
                label: labels?.driverPage?.driverInfo?.Language,
                value: damageData?.thirdPartyLanguage,
                name: "thirdPartyLanguage",
                type: "select"
            }
        ]
        content = (
            <div className="damage-details-page-content">
                <div className="header-button-wrapper">
                    <h1>{labels?.damagesPage?.titles?.damageDetails}</h1>
                    <EditButtonGroup
                        isLoading={isUpdateLoading}
                        isEditing={isEditing}
                        allowEdit={allowEdit}
                        setEditingMode={setIsEditing}
                        handleEnableEdit={handleEnableEdit}
                        handleCancelEdit={() => {
                            setIsEditing(false);
                            setDamageData(data)
                        }}
                        handleSave={handleSave}
                        labels={labels}
                    ></EditButtonGroup>
                </div>

                {
                    isUpdateError ? cannotSaveError : <></>
                }
                <div className="card-container">
                    {/* Vehicle Card*/}
                    <InfoCard title={labels?.damagesPage?.damageInfo?.vehicle}>
                        <div className="card-row-content">
                            <CarImage className="vehicle-image" alt="car image" />
                            <div className="car-content-right-side">
                                {isEditing ?
                                    <SearchForCar originalData={damageData?.vehicleAax}
                                        validSelectedVehicle={validSelectedVehicle}
                                        setValidSelectedVehicle={setValidSelectedVehicle} /> :
                                    <>
                                        <div className="item-group-container">
                                            <div className="item-group-column">
                                                <InformationItem label={labels?.carsPage?.carInfo?.brand}>
                                                    {damageData?.vehicleAax?.brand || "-"}
                                                </InformationItem>
                                                <InformationItem label={labels?.carsPage?.carInfo?.VIN}
                                                    editMode={false}>
                                                    {damageData?.vehicleAax?.vin ? <Link to={"/cars/" + damageData?.vehicleAax?.vin + "?tab=car-details"}>
                                                        <span className="text-with-hover">
                                                            {damageData?.vehicleAax?.vin}
                                                        </span>
                                                    </Link> : "-"}
                                                </InformationItem>

                                            </div>
                                            <div className="item-group-column">
                                                <InformationItem label={labels?.carsPage?.carInfo?.type}
                                                    editMode={false}>
                                                    {damageData?.vehicleAax?.type || "-"}
                                                </InformationItem>

                                                <InformationItem label={labels?.carsPage?.carInfo?.plateNumber}
                                                    editMode={false}>
                                                    {damageData?.vehicleAax?.licencePlate || "-"}
                                                </InformationItem>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>

                    </InfoCard>

                    {/* Insurance Card*/}
                    <InfoCard title={labels?.damagesPage?.damageInfo?.insurance}>
                        <div className="card-row-content">
                            <div className="item-group-container">

                                <div className="item-group-column">
                                    <InformationItem label={labels?.damagesPage?.damageInfo?.referenceNumber}
                                        editMode={isEditing}>
                                        {isEditing ?

                                            <FormInputItem
                                                name="referenceNumber"
                                                value={damageData?.referenceNumber}
                                                handleChange={handleChange}
                                            />

                                            : damageData?.referenceNumber || "-"}
                                    </InformationItem>

                                    <InformationItem label={labels?.damagesPage?.damageInfo?.insuranceCompanyName}
                                        editMode={isEditing}>
                                        {isEditing ?

                                            <FormInputItem
                                                name="insuranceCompanyName"
                                                value={damageData?.insuranceCompanyName}
                                                handleChange={handleChange}
                                            />

                                            : damageData?.insuranceCompanyName || "-"}
                                    </InformationItem>

                                </div>

                                <div className="item-group-column">
                                    <InformationItem label={labels?.damagesPage?.damageInfo?.insurancePolicyNumber}
                                        editMode={isEditing}>
                                        {isEditing ?
                                            <FormInputItem
                                                name="insurancePolicyNumber"
                                                value={damageData?.insurancePolicyNumber}
                                                handleChange={handleChange}
                                            />

                                            : damageData?.insurancePolicyNumber || "-"}
                                    </InformationItem>

                                    {/*TODO: policy owner is commented out because it needs to be a valid client id, we can not
                                        just let the user type in. It should be at least a search function
                                        */}
                                    {/*<InformationItem label={labels?.damagesPage?.damageInfo?.policyOwner}*/}
                                    {/*                 editMode={isEditing}>*/}
                                    {/*    {isEditing ?*/}

                                    {/*        <FormInputItem*/}
                                    {/*            name="policyOwner"*/}
                                    {/*            value={damageData?.policyOwner}*/}
                                    {/*            type={"number"}*/}
                                    {/*            handleChange={handleChange}*/}
                                    {/*        />*/}

                                    {/*        : damageData?.policyOwner || "-"}*/}
                                    {/*</InformationItem>*/}
                                </div>

                            </div>
                        </div>
                    </InfoCard>

                    {/* Repair Card*/}
                    <InfoCard title={labels?.damagesPage?.damageInfo?.repair}>
                        <div className="damages-card-content"
                            style={{ justifyContent: "center", gap: "20px", minWidth: "fit-content" }}>
                            <div className="item-group-row">
                                <InformationItem label={labels?.damagesPage?.damageInfo?.estimatedDamage}
                                    editMode={false} valueStyle={{ color: "#00008F" }}>
                                    {isEditing ?
                                        <FormInputItem
                                            name="vehicleEstimatedLoss"
                                            value={damageData?.vehicleEstimatedLoss}
                                            handleChange={handleChange}
                                            type="number"
                                        />
                                        : (
                                            damageData?.vehicleEstimatedLoss >= 0 ?
                                                "CHF " + formatNumber(Number(damageData?.vehicleEstimatedLoss))
                                                :
                                                "-"
                                        )
                                    }
                                </InformationItem>
                            </div>
                            <div className="item-group-row">
                                <InformationItem label={labels?.damagesPage?.damageInfo?.damageStatus} editMode={false}>
                                    {isEditing ?
                                        <select
                                            className="input-field-primary-damage-details"
                                            name="amsDamageStatus"
                                            value={damageData?.amsDamageStatus}
                                            onChange={handleChange}
                                            style={{ height: "35px", padding: "0 5px" }}
                                            disabled={!isEditing}
                                        >
                                            <option value="0">-</option>
                                            <option value="1">{getDamageStatus(1, labels)}</option>
                                            <option value="2">{getDamageStatus(2, labels)}</option>
                                        </select>
                                        : (getDamageStatus(damageData?.amsDamageStatus))
                                    }
                                </InformationItem>
                            </div>
                        </div>


                    </InfoCard>

                    {/* Damage Card*/}
                    <InfoCard title={labels?.damagesPage?.damageInfo?.damage}
                    //style={{maxWidth: "700px"}}
                    >
                        <div className="damages-card-content">
                            <div className="item-group-row">
                                <InformationItem label={labels?.damagesPage?.damageInfo?.damageDate}
                                    editMode={isEditing}>
                                    {isEditing ?
                                        <FormInputItem
                                            name="damageDate"
                                            type="date"
                                            value={formatDateHtmlFormat(damageData?.damageDate)}
                                            handleChange={handleChange}
                                        />

                                        : formatDateIfNotEmpty(data?.damageDate)}
                                </InformationItem>

                                <InformationItem label={labels?.damagesPage?.damageInfo?.damageCause}
                                    editMode={isEditing}>
                                    {isEditing ?
                                        <select
                                            className="input-field-primary-damage-details"
                                            name="damageCauseShort"
                                            value={damageData?.damageCauseShort}
                                            onChange={handleChange}
                                            style={{ height: "35px", padding: "0 5px" }}
                                            disabled={!isEditing}
                                        >
                                            {Object.keys(damageMap).map(key => <option value={key}>{getDamageType(key, labels)}</option>)}
                                        </select>

                                        : getDamageType(damageData?.damageCauseShort, labels)
                                    }


                                    {/*{isEditing ?*/}

                                    {/*    <FormInputItem*/}
                                    {/*        name="damageCauseShort"*/}
                                    {/*        value={damageData?.damageCauseShort}*/}
                                    {/*        handleChange={handleChange}*/}
                                    {/*    />*/}

                                    {/*    : damageData?.damageCauseShort || "-"}*/}
                                </InformationItem>
                            </div>

                            <InformationItem label={labels?.damagesPage?.damageInfo?.description} editMode={isEditing}>

                                {isEditing ?

                                    <textarea
                                        className="damage-description-container edit"
                                        name="description"
                                        value={damageData?.description}
                                        onChange={handleChange}
                                        autoComplete={"on"}
                                    />

                                    :
                                    <div className="damage-description-container">
                                        {damageData?.description || "-"}
                                    </div>
                                }

                            </InformationItem>

                            <div className="item-group-row">
                                <InformationItem label={labels?.driverPage?.driverInfo?.country} editMode={isEditing}>
                                    {isEditing ?
                                        <select
                                            className="input-field-primary-damage-details"
                                            name="damageLocation.country.id"
                                            value={damageData?.damageLocation?.country?.id}
                                            onChange={handleChange}
                                            style={{ height: "35px", padding: "0 5px" }}
                                            disabled={!isEditing}
                                        >
                                            <option value="-">-</option>
                                            <option value="1">{getCountryName(1, labels)}</option>
                                            <option value="2">{getCountryName(2, labels)}</option>
                                            <option value="200">{getCountryName(200, labels)}</option>
                                            <option value="68">{getCountryName(68, labels)}</option>
                                            <option value="100">{getCountryName(100, labels)}</option>
                                            <option value="184">{getCountryName(184, labels)}</option>
                                        </select>

                                        : getCountryName(damageData?.damageLocation?.country?.id, labels)
                                    }
                                </InformationItem>

                                <InformationItem label={labels?.driverPage?.driverInfo?.street} editMode={isEditing}>
                                    {isEditing ?

                                        <FormInputItem
                                            name="damageLocation.street"
                                            value={damageData?.damageLocation?.street}
                                            handleChange={handleChange}
                                        />

                                        : damageData?.damageLocation?.street || "-"}
                                </InformationItem>

                                <InformationItem label={labels?.driverPage?.driverInfo?.city} editMode={isEditing}>
                                    {isEditing ?

                                        <FormInputItem
                                            name="damageLocation.city"
                                            value={damageData?.damageLocation?.city}
                                            handleChange={handleChange}
                                        />

                                        : damageData?.damageLocation?.city || "-"}
                                </InformationItem>

                                <InformationItem label={labels?.driverPage?.driverInfo?.zip} editMode={isEditing}>
                                    {isEditing ?

                                        <FormInputItem
                                            name="damageLocation.zip"
                                            value={damageData?.damageLocation?.zip}
                                            handleChange={handleChange}
                                        />

                                        : damageData?.damageLocation?.zip || "-"}
                                </InformationItem>
                            </div>

                            <div className="item-group-row"
                                style={{ alignItems: "center", justifyContent: "flex-start" }}>
                                <PersonIcon className="contact-person-icon" alt="person icon" />

                                <InformationItem editMode={isEditing}
                                    label={labels?.damagesPage?.damageInfo?.contactPersonName}>
                                    {isEditing ?

                                        <FormInputItem
                                            name="contactPersonName"
                                            value={damageData?.contactPersonName}
                                            handleChange={handleChange}
                                        />

                                        : damageData?.contactPersonName || "-"}
                                </InformationItem>

                                <InformationItem editMode={isEditing} label={labels?.driverPage?.driverInfo?.phone}>
                                    {isEditing ?

                                        <FormInputItem
                                            name="contactPersonPhone.cli"
                                            value={damageData?.contactPersonPhone?.cli}
                                            handleChange={handleChange}
                                        />

                                        : damageData?.contactPersonPhone?.cli || "-"}
                                </InformationItem>

                            </div>
                        </div>
                    </InfoCard>

                    {/* Notes Card*/}
                    <div className="notes-card-container">
                        <InfoCard title={labels?.damagesPage?.damageInfo?.notes}>
                            {isEditing ?

                                <textarea
                                    name="notes"
                                    value={damageData?.notes}
                                    onChange={handleChange}
                                    className="notes-textarea"
                                    style={{ width: "100%", height: "100%" }}
                                />

                                : damageData?.notes || "-"}
                        </InfoCard>
                    </div>

                    {/* Third Party Card*/}
                    <InfoCard
                        title={labels?.damagesPage?.damageInfo?.thirdParty}
                        hidden={!thirdPartyFields.some((field) => field.value) && !isEditing}
                        style={{ maxWidth: "550px" }}
                    >
                        <div className="third-party-content">
                            <div className="third-party-labels">
                                {thirdPartyFields.map((item, index) => (
                                    <div>{item?.label}</div>
                                ))}
                            </div>
                            <div className="third-party-values" style={{ gap: isEditing ? "5px" : 0 }}>
                                {isEditing ?
                                    thirdPartyFields.map((item, index) => {
                                        if (item?.type === "select") {
                                            return (
                                                <select
                                                    className="input-field-primary-damage-details"
                                                    name={item?.name}
                                                    value={item?.value}
                                                    onChange={handleChange}
                                                    style={{ height: "35px", padding: "0 5px" }}
                                                >
                                                    <option value="-"></option>
                                                    <option value="1">Deutsch</option>
                                                    <option value="2">English</option>
                                                    <option value="3">Français</option>
                                                    <option value="4">Italiano</option>

                                                </select>
                                            )
                                        }
                                        if (item?.type === "date") {
                                            return (
                                                <FormInputItem
                                                    name={item?.name}
                                                    value={formatDateHtmlFormat(item?.value)}
                                                    handleChange={handleChange}
                                                    type="date"
                                                    style={{ height: "35px" }}
                                                />
                                            )
                                        }
                                        return (
                                            <FormInputItem
                                                name={item?.name}
                                                value={item?.value}
                                                handleChange={handleChange}
                                                type={item?.type || "text"}
                                                style={{ height: "35px" }}
                                            />)
                                    })
                                    :
                                    thirdPartyFields.map((item, index) => {
                                        if (item?.name === "thirdPartyLanguage") {
                                            return (
                                                <div> {getLanguage(item?.value)}</div>
                                            )
                                        }
                                        if (item?.name === "thirdPartyBirthdate") {
                                            return (
                                                <div
                                                    className="isThisbirthday"> {formatDateIfNotEmpty(item?.value) || "-"}</div>
                                            )
                                        }
                                        return (
                                            <div> {item?.value || "-"}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </InfoCard>
                </div>
            </div>
        )
    } else {
        content = createErrorMessage(error, labels?.damagesPage?.titles?.damageDetails, "h1")
    }

    return (
        <div className="content-side content-side-padding">
            <BreadcrumbNav />
            {content}
        </div>
    )
}

export default DamageDetailsPage;