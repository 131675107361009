import { useMemo, useRef, useState } from "react";
import moment from "moment";
import DatePickerHandler from "../../../components/DatePickerHandler";
import '../_style.scss';
import {
    MetricCard,
    MetricCardBody,
    MetricCardHeader,
    MetricCardIcon,
    MetricCardTitle
} from "../../../components/MetricCard";
import { ReactComponent as RoadIcon } from "../../../assets/svg/icon-road.svg";
import { ReactComponent as WindIcon } from "../../../assets/svg/icon-wind.svg";
import { ReactComponent as GasIcon } from "../../../assets/svg/icon-gas.svg";
import { ReactComponent as LightningIcon } from "../../../assets/svg/icon-lightning.svg";
import FormModal from "../../../components/FormModal";
import KmStatusGridTable from "./KmStatusGridTable";

import { DataStatusBadge } from "../index";
import { thousandsSeparator } from "../../../actions/formateNumbers.actions";
import { calculateKmDriven } from "../../../actions/kmDriven.action";
import { EmissionsChart } from "../Charts/EmissionsChart";
import { useGetCo2EmissionsQuery, useGetFleetKmQuery } from "../../../redux/apiSlice/dashboardApiSlice";
import { useGetUserQuery } from "../../../redux/apiSlice/userApiSlice";
import { Skeleton } from "../../../components/Skeleton";

export const FleetPerformanceSection = ({ labels }) => {

    // Last month for date range
    const [startDate, setStartDate] = useState(moment().startOf('year').toDate());
    const [endDate, setEndDate] = useState(moment(new Date()).toDate());

    // state for modal
    const [isKmModalVisible, setIsKmModalVisible] = useState(false)
    function handleRangeChange(start, end) {
        // Change the dates
        if (start && end) {
            setStartDate(start)
            setEndDate(end)
        }
        // If user deselects the dates, set to null
        else if (!start && !end) {
            setStartDate(null);
            setEndDate(null);
        }
    }

    const {
        data: userData,
    } = useGetUserQuery()

    const customerId = userData?.customer;


    const {
        data: emissionData,
        isFetching: emissionIsFetching,
        isError: emissionIsError,
        isSuccess: emissionIsSuccess,
    } = useGetCo2EmissionsQuery({
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null
    });

    const {
        data: kmData,
        isFetching: kmDataIsFetching,
        isError: kmDataIsError,
        isSuccess: kmDataIsSuccess,
    } = useGetFleetKmQuery({
        customerId: customerId,
        startDt: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        endDt: endDate ? moment(endDate).format('YYYY-MM-DD') : null
    });

    const {kmDriven,kmDataValid} = calculateKmDriven(kmData)

    // This needs to be stored in a useRef because otherwise we can't set it to false inside the useMemo
    let fueldBasedEmissionDataValid = useRef(true)
    let fuelConsumption = useRef({})
    // This extracts the co2 emissions into a more structured object, useMemo because it's probably pretty computationally
    // expensive and we should avoid unnecessary re-computations.
    const co2EmissionsBasedOnFuel = useMemo(() => {
        if (!emissionData || !emissionData?.length) return {}
        // Reset the fuelConsumption object, otherwise it adds multiples
        fuelConsumption.current = {}
        return emissionData?.map((vehicleEntry) => vehicleEntry?.fuelDataList)?.reduce((acc, obj) => {
            for (const fuelEntry of obj) {
                // If for some reason a fuelEntry has no fuelType, continue to not crash
                if (!fuelEntry.fuelType) continue
                // This does the same as 'if the key does not exist yet, initialize it at 0'
                acc[fuelEntry.fuelType] = acc[fuelEntry.fuelType] ?? 0;
                fuelConsumption.current[fuelEntry.fuelType] = fuelConsumption.current[fuelEntry.fuelType] ?? 0;
                // Check if it has co2 emission value, if it does, add it to the new object, otherwise set valid bool to false
                if (fuelEntry?.co2Emissions) {
                    acc[fuelEntry.fuelType] += fuelEntry.co2Emissions / 1000
                } else {
                    fueldBasedEmissionDataValid.current = false;
                }
                if (fuelEntry?.fuelConsumption) {
                    fuelConsumption.current[fuelEntry.fuelType] += fuelEntry.fuelConsumption;
                }
            }
            // Return the modified object after each reduction
            return acc;
        }, {})
    }, [emissionData])
    const co2EmissionBasedOnWLTP =useMemo(()=>{
        if (!kmData || !kmData?.length) return {}
        let result = {}
        kmData.forEach(car => {
            if(car.fuelType){
                if(car.fuelType in result){
                    result[car.fuelType]+= (car.co2Emission??0)/ 1000
                }else{
                    result[car.fuelType]=(car.co2Emission??0)/ 1000
                }
            }
        });
        return result
    },[kmData])
    const totalEmissions = Object.values(co2EmissionBasedOnWLTP).reduce((partialSum, a) => partialSum + a, 0);
    return (
        <div className="fleet-performance-content">
            <div style={{ width: "fit-content" }}>
                <DatePickerHandler
                    isRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    onChange={handleRangeChange}
                />
            </div>

            <div className="performance-metrics">
                <MetricCard onClick={() => setIsKmModalVisible(true)} className="card-with-hover" style={{ flexGrow: 1, minWidth: '300px' }} hidden={kmDataIsError}>
                    <MetricCardHeader>
                        <MetricCardTitle>
                            {labels?.homePage?.metrics?.drivenKms}
                        </MetricCardTitle>
                    </MetricCardHeader>
                    <MetricCardBody>
                        <MetricCardIcon>
                            <RoadIcon width={19} height={19} />
                        </MetricCardIcon>
                        {kmDataIsFetching ? <Skeleton height="25px" width="100%" /> : (thousandsSeparator(Math.round(kmDriven)) || 0) + ' km'}
                    </MetricCardBody>
                    {
                        kmDataIsSuccess && !kmDataIsFetching && <DataStatusBadge labels={labels} isValid={kmDataValid} />
                    }
                </MetricCard>
            </div>

            <FormModal
                style={{ minWidth: "800px" }}
                show={isKmModalVisible}
                onClose={() => setIsKmModalVisible(false)}
                title={<div>
                    {/* Yue: atm there is no way to set date as empty. */}
                    {labels?.homePage?.metrics?.drivenKms} {moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
                </div>}
            >
                {/* Yue: atm there is no way to set them as empty. */}
                <p>{moment(startDate).format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}</p>
                <KmStatusGridTable data={kmData}></KmStatusGridTable>
            </FormModal>

            <div className='emission-section'>
                <MetricCard hidden={emissionIsError || (emissionIsSuccess && (!emissionData || !emissionData.length))} style={{ width: '100%' }}>
                    {/* the order of items below is reverse on the screen due to the use of the css flex-direction column-reverse */}
                    {emissionIsFetching||kmDataIsFetching ? <Skeleton height="300px" width="100%" /> : <EmissionsChart dataBasedOnWLTP={co2EmissionBasedOnWLTP} dataBasedOnFuel={co2EmissionsBasedOnFuel} labels={labels} />}

                    <MetricCardHeader>
                        <MetricCardTitle>
                            {labels?.homePage?.metrics?.totalCo2EmissionsBasedOnWLTP}
                        </MetricCardTitle>
                    </MetricCardHeader>

                    <MetricCardBody>
                        <MetricCardIcon>
                            <WindIcon width={19} height={19} />
                        </MetricCardIcon>
                        {thousandsSeparator(Math.round(totalEmissions * 100) / 100)} kg
                    </MetricCardBody>

                    {
                        emissionIsSuccess && !emissionIsFetching && <DataStatusBadge labels={labels} isValid={fueldBasedEmissionDataValid.current} />
                    }

                </MetricCard>

                <div className='emission-cards' data-empty={emissionIsError || (emissionIsSuccess && (!emissionData || !emissionData.length))}>
                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle>
                                {labels?.homePage?.metrics?.gasoline}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <GasIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.gasoline] ?? 0) + ' l'}
                        </MetricCardBody>
                    </MetricCard>


                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle>
                                {labels?.homePage?.metrics?.diesel}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <GasIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.diesel] ?? 0) + ' l'}
                        </MetricCardBody>
                    </MetricCard>

                    <MetricCard style={{ flexGrow: 1, height: 'fit-content' }} hidden={emissionIsError}>
                        <MetricCardHeader>
                            <MetricCardTitle>
                                {labels?.homePage?.metrics?.electric}
                            </MetricCardTitle>
                        </MetricCardHeader>
                        <MetricCardBody>
                            <MetricCardIcon>
                                <LightningIcon width={19} height={19} />
                            </MetricCardIcon>
                            {emissionIsFetching ? <Skeleton height="25px" width="100%" /> : (fuelConsumption.current[labels?.homePage?.metrics?.electric] ?? 0) + ' kWh'}
                        </MetricCardBody>
                    </MetricCard>
                </div>

            </div>


        </div>

    )
}
