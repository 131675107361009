// import external
import React from "react";
import {Link} from "react-router-dom";

// import internal slices
import {useGetCarsQuery} from "../../redux/apiSlice/carApiSlice";

// import helper function
import {findNewestValidEntry, formatDateIfNotEmpty, isObjectNotObsolete} from "../../actions/date.actions"
// import styling
import "./_style.scss"

// import assets
import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as CanceledCarIcon} from "../../assets/svg/icon-canceled-cars.svg";
import {ReactComponent as OrderedCarIcon} from "../../assets/svg/icon-ordered-cars.svg";
import {ReactComponent as MagnifierIcon} from "../../assets/svg/icon-magnifier.svg";
import {ReactComponent as NoContractIcon} from "../../assets/svg/icon-no-contract.svg";

//import {ReactComponent as NoContractIcon} from "../../assets/svg/icon-file-question-mark.svg";
// import internal components
import {createErrorMessage} from "../AlertContainer/alert.utils";
import GridHandler from "../GridHandler";
import LoadingComponent from "../LoadingComponent";
import KeyInfoCard from "../KeyInfoCard";
import {dateComparator, dateNowComparator} from "../GridHandler/dates.actions";
import FilterTypes from "../GridHandler/FilterUtil/FilterTypes";
import {thousandsSeparator} from "../../actions/formateNumbers.actions";
import GridTitle from "../GridHandler/GridTitle";
import {FilterButtonItem} from "../FilterButtonGroup";
import {getCarStatus, getContractStatus} from "../../actions/magicNumber.actions";
import {newStatusCellRenderer} from "../GridHandler/CellRenderers/newStatusCellRenderer";
import FleetWizard from "../FleetWizard";
import {useSelector} from "react-redux";
import {useVariableValue} from "@devcycle/react-client-sdk";



export function determineContractStatusColor(statusId, statusText){
    if (statusId === 1) {
        return newStatusCellRenderer({ value: statusText, color: "positive"});
    }
    // If statusId 0 or 5 (New or Ordered) then blue color
    else if (statusId === 0 || statusId === 5){
        return newStatusCellRenderer({ value: statusText, color: "neutral"});
    }
    // If no contract then grey color to match the Key number card
    else if (statusId ===undefined){
        return newStatusCellRenderer({ value: statusText, color: "grey"});
    }
    // Else red color
    else {
        return newStatusCellRenderer({ value: statusText, color: "negative"});
    }
}

export function determineCarStatusColor(status, languageCode){
    const statusValue = status.replace(/\s/g, '').toLowerCase()
    const statusText = getCarStatus(status, languageCode)
    switch (statusValue) {
        case "lager/parkplatz":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "eingang":
            return newStatusCellRenderer({ value: statusText, color: "yellow"})
        case "inaufbereitung":
            return newStatusCellRenderer({ value: statusText, color: "orange"});
        case "schadenreparatur":
            return newStatusCellRenderer({ value: statusText, color: "negative"});
        case "aktiv":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "verfügbar":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "vermietet":
            return newStatusCellRenderer({ value: statusText, color: "positive"});
        case "ausgeliefert":
            return newStatusCellRenderer({ value: statusText, color: "darkgreen"});
        case "inrücknahme":
            return newStatusCellRenderer({ value: statusText, color: "neutral"});
        case "inaktiv":
            return newStatusCellRenderer({ value: statusText, color: "darkred"});
        case "":
            return null;
        default:
            return newStatusCellRenderer({ value: statusText, color: "grey"});
    }

}

/**
 *
 * @param {labels} props
 * loads data for the car overview and defines what to show
 * @returns
 */
const CarOverview = (props) => {
    // load cars from endpoint
    const {
        data: carData,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCarsQuery();

    const languageCode = useSelector(state => state.labels?.currentLanguage);

    const showSpecificCarStatus = useVariableValue("specific-car-status", false)
    // each column definition results in one column.
    const columnDefs = [
        {
            cellRenderer: function (params) {
                return (
                    <Link to={"/cars/" + params.data?.vin + "?tab=car-details"}>
                        <MagnifierIcon className="grid-icon" alt="open row details icon"/>
                    </Link>
                );
            },
            pinned: 'left',
            resizable: false,
            sortable: false,
            filter: false,
            width:60,
            suppressMenu: true,
            colId:"icon",
            suppressMovable:true
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.brand,
            valueGetter: function (params) {
                return params.data?.brand;
            },
            colId:"brand"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.type,
            valueGetter: function (params) {
                return params.data?.type;
            },
            colId:"type"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.plateNumber,
            colId:"plateNumber",
            valueGetter: function (params) {
                return params.data?.licencePlate;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.VIN,
            colId:"VIN",
            valueGetter: function (params) {
                return params.data?.vin;
            }
        },
        // Currently the logic to find the newest valid driver and the newest valid location is not the same
        // See Jira AMS-52
        {
            headerName: props.labels?.carsPage?.carInfo?.driver,
            colId:"driver",
            valueGetter: function (params) {
                if (params.data.additionalCarInformation?.drivers && params.data.additionalCarInformation?.drivers[0]) {
                    // only get drivers with no endDate (still active)
                    let activeDrivers = params.data.additionalCarInformation?.drivers.filter(driver => isObjectNotObsolete(driver.endDate));
                    // return first active driver in array because result should already be sorted by aax
                    if (activeDrivers && activeDrivers.length > 0) {
                        return activeDrivers[0].firstName + " " + activeDrivers[0].lastName;
                    } else {
                        return "";
                    }
                }
                return "";
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.location,
            colId:"location",
            valueGetter: function (params) {
                const newestLocationIndex = findNewestValidEntry(
                    params.data?.additionalCarInformation?.locations
                )
                if (newestLocationIndex !== null && newestLocationIndex !== undefined) {
                    return params.data?.additionalCarInformation?.locations[newestLocationIndex]?.location
                }
                //const newestLocation = params.data?.additionalCarInformation?.locations?.length
                // if newestLocationIndex is null or params.data.additionalCarInformation?.locations is empty[],
                // newestLocation?.location is undefined. No error.
                //return newestLocation?.location
                return '';
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.status,
            colId:"carStatus",
            valueGetter: (params) => params.data?.status,
            filterValueGetter: (params) => getCarStatus(params.data?.status, languageCode || "DE"),
            cellRenderer: (params) => {
                return determineCarStatusColor(params.data?.status, languageCode || "DE")
            },
            hide: !showSpecificCarStatus,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.transmissionType,
            colId:"transmissionType",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.transmissionType;
            },
        },

        {
            headerName: props.labels?.carsPage?.carInfo?.kmStatus,
            colId:"kmStatus",
            valueGetter: function (params) {
                const telematicData = params.data.additionalCarInformation?.telematicData
                return telematicData && telematicData[0] ?
                    telematicData[0].odometerValue : "";
            },
            valueFormatter: function (params) {
                return thousandsSeparator(params.value);
            },
            type: 'rightAligned',
            filter:"agNumberColumnFilter"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.kmStatusDate,
            valueGetter: function (params) {
                const telematicData = params.data.additionalCarInformation?.telematicData
                return telematicData && telematicData[0] ?
                    formatDateIfNotEmpty(telematicData[0].date) : "";
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.contractStatus,
            colId:"contractStatus",
            // Switch to this once contract status Id is implemented, use getVehicleStatus from magicNumbers
            valueGetter: (params) => params.data?.contractInformation?.contractStatusId,
            //valueFormatter:(params) => getVehicleStatus(params.data?.contractInformation?.contractStatusId, props.labels),
            // This is used so that if you type in "Active" in the filter, it will show all cars with status 1
            filterValueGetter: (params) => getContractStatus(params.data?.contractInformation?.contractStatusId, props.labels),
            cellRenderer: (params) => {
                const statusId = params.data?.contractInformation?.contractStatusId;
                const statusText = getContractStatus(statusId, props.labels) // For testing: + " (" + statusId + ")";
                return determineContractStatusColor(statusId,statusText)
            },
            hide: showSpecificCarStatus,
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.companyName,
            colId:"companyName",
            valueGetter: function (params) {
                let companyName = params.data.contractInformation?.contractOrganisation?.companyName;
                return companyName;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.companyName + " 2",
            colId:"companyName2",
            valueGetter: function (params) {
                let companyName2 = params.data.contractInformation?.contractOrganisation?.companyName2;
                return companyName2;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.contractStart,
            colId:"contractStart",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.contractInformation?.contractStartDate)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.contractEnd,
            colId:"contractEnd",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.contractInformation?.contractEndDate)
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },

        {
            headerName: props.labels?.carsPage?.carInfo?.idNumber,
            colId:"idNumber",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.serialNumber;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.typeLicence,
            colId:"typeLicence",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.typeCertificationNumber;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.firstRegistration,
            colId:"firstRegistrationDate",
            valueGetter: function (params) {
                return formatDateIfNotEmpty(params.data.additionalCarInformation?.baseData?.registrationDate);
            },
            filter: 'agDateColumnFilter',
            // sorting related
            comparator: dateComparator,
            // filter related
            filterParams: {
                // provide comparator function
                comparator: dateNowComparator
            }
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.co2,
            colId:"co2",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.co2emission;
            },
            filter:"agNumberColumnFilter"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.engineDisplacement,
            colId:"engineDisplacement",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.engineDisplacement;
            },
            filter:"agNumberColumnFilter"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.performance,
            colId:"performance",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.baseData?.powerInKw;
            },
            filter:"agNumberColumnFilter"
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.fuelType,
            colId:"fuelType",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.fuelData?.fuelType;
            },
        },
        {
            headerName: props.labels?.carsPage?.carInfo?.numberOfSeats,
            colId:"numberOfSeats",
            valueGetter: function (params) {
                return params.data.additionalCarInformation?.weightData?.numberOfSeats;
            },
        }
    ];

    let content;

    // show loading text (spinner) while loading
    if (isLoading) {
        content = <LoadingComponent show={true}></LoadingComponent>;
    } else if (isSuccess) { // handle successful data loading
        let {cars, uniqueFields, uniqueFieldDescriptions} = carData;

        // filter out cars that have status 18 (in erstellung).
        // Yue: comment out line below because new api might not need filtering anymore. I do not recall why 21 is left out
        // In case in the future we notice the need to filter out some cars, this line is commented out but not deleted
        // let filtered_cars = cars.filter(car => car.contractInformation?.contractStatusId !== 18 && car.contract?.status?.id !== 21);

        let numActiveCars = cars.filter(car => car.contractInformation?.contractStatusId === 1)?.length;
        let numOrderedCars = cars.filter(car => car.contractInformation?.contractStatusId === 0)?.length;
        let numCanceledCars = cars.filter(car => car.contractInformation?.contractStatusId === 9 || car.contractInformation?.contractStatusId === 6)?.length;

        // Cars with no contract don't have the contractInformation object
        let numCarsNoContract = cars.filter(car => !car?.contractInformation).length;

        // add the customer defined (Kundenfelden) columns to ag grid column definition
        uniqueFields.forEach((columnName, index) => {
            let headerName=props.labels?.carsPage?.carInfo[columnName] || uniqueFieldDescriptions[index]
            let newColum = {
                headerName: headerName,
                colId:columnName,
                valueGetter: function (params) {
                    return params.data.additionalCarInformation?.customerFields[columnName];
                },
            }
            columnDefs.push(newColum)
            ;
        });
        // if property is available

        if (cars.length > 0 && cars[0]?.additionalCarInformation) {
            // show grid with the data
            content = (
                <>
                    <div className="number-cards-container">
                        <KeyInfoCard
                            title={props.labels?.carsPage?.keyNumbers?.active}
                                     number={numActiveCars}
                                     cardStyle={"card-positive"}
                                     unit={""}
                        >
                            <CarIcon className={"card-icon"}></CarIcon>
                        </KeyInfoCard>
                        <KeyInfoCard title={props.labels?.carsPage?.keyNumbers?.ordered}
                                     number={numOrderedCars}
                                     cardStyle={"card-neutral"}
                                     unit={""}
                        >
                            <OrderedCarIcon className={"card-icon"}></OrderedCarIcon>
                        </KeyInfoCard>
                        <KeyInfoCard title={props.labels?.carsPage?.keyNumbers?.canceled}
                                     number={numCanceledCars}
                                     cardStyle={"card-negative"}
                                     unit={""}
                        >
                            <CanceledCarIcon className={"card-icon"}></CanceledCarIcon>
                        </KeyInfoCard>
                        <KeyInfoCard title={props.labels?.carsPage?.keyNumbers?.noContract}
                                     number={numCarsNoContract}
                                     cardStyle={"card-orange"}
                                     unit={""}
                        >
                            <NoContractIcon className={"card-icon"}></NoContractIcon>
                        </KeyInfoCard>
                    </div>
                    <GridHandler
                        gridTitle={
                            <GridTitle
                                title={props.labels?.carsPage?.titles?.fleetTableTitle}
                                subtitle={props.labels?.carsPage?.titles?.fleetTableSubtitle}
                                badgeText={cars.length + " " + props.labels?.carsPage?.titles?.vehicles}
                            />}
                        data={cars}
                        columnDefs={columnDefs}
                        downloadable={true}
                        gridName={`upto_cars_overview`}
                        fileName={`upto_${props.labels?.carsPage?.titles?.carDetails}`}
                        downloadTextCsv={props.labels.otherElements.downloadCsv}
                        downloadTextExcel={props.labels.otherElements.downloadExcel}
                        filterItems={[
                            <FilterButtonItem id={0} text={props.labels.carsPage?.filter?.all} filterType={FilterTypes.ALL}/>,
                            <FilterButtonItem id={1} text={props.labels.carsPage?.filter?.activeOnly} filterType={FilterTypes.VEHICLES_ONLY_ACTIVE}/>,
                        ]}
                        defaultFilterType={FilterTypes.VEHICLES_ONLY_ACTIVE}
                    ></GridHandler>
                </>
            )
        } else {
            // if user doesn't have additional car information, which means no fleet manager role
            content = (
                createErrorMessage(error, null, null, props.labels)
            )
        }
    } else if (isError) {
        // show different error messages based on status code
        content = createErrorMessage(error, null, null, props.labels);

    }
    return (
        <>
            <div className="fleet-header">
                <h1>{props.title}</h1>
                <FleetWizard initialTab={"vehicle"} labels={props?.labels}>
                    {props?.labels?.fleetWizard?.titles?.addVehicle}
                </FleetWizard>
            </div>
            {content}
        </>

    )
}

export default CarOverview