import {
    FormDescription,
    FormField, FormGroup, FormItem, FormLabel, FormMessage, FormValue, Input, Select
} from '../Form/Form';
import {createCarSchema, createDriverSchema, createFuelCardSchema} from "../Form/FormSchemas";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import FormModal from "../FormModal";
import React, {useState} from "react";

// import styling
import "./_style.scss"

import {ReactComponent as CarIcon} from "../../assets/svg/icon-car.svg";
import {ReactComponent as DriverIcon} from "../../assets/svg/icon-person.svg";
import {ReactComponent as CardIcon} from "../../assets/svg/icon-card.svg";


import {ReactComponent as SuccessIcon} from "../../assets/svg/icon-check-circle.svg";
import {ReactComponent as ErrorIcon} from "../../assets/svg/icon-warning.svg";
import {ReactComponent as PlusIcon} from "../../assets/svg/icon-plus.svg";
import {ReactComponent as CloseIcon} from "../../assets/svg/icon-close.svg";

import FeatureIcon from "../FeatureIcon";
import {
    useAddDriverToCarMutation, useCreateDriverMutation,
    useGetDriversQuery,
    useGetOrganisationsQuery
} from "../../redux/apiSlice/driverApiSlice";
import {useGetFuelCardsQuery} from "../../redux/apiSlice/fuelCardApiSlice";

import {Combobox, ComboboxInput, ComboboxItem, ComboboxList} from "../Form/Combobox/Combobox";

import {StatusMessage, StatusMessageContent, StatusMessageIcon, StatusMessageTitle} from "../StatusMessage";
import {useCreateCarMutation, useGetCarsQuery} from "../../redux/apiSlice/carApiSlice";
import {useGetUserQuery} from "../../redux/apiSlice/userApiSlice";
import {useSelector} from "react-redux";
import {getLanguageName} from "../../actions/magicNumber.actions";
import {formatDateIfNotEmpty} from "../../actions/date.actions";
import {useUploadDocumentMutation} from "../../redux/apiSlice/documentsApiSlice";
import {useVariableValue} from "@devcycle/react-client-sdk";
import {FormControlButtons} from "../Form/FormControlButtons";


const FleetWizard = ({initialTab, labels, ...props}) => {

    // Visibility of modal (FleetWizard)
    const [showModal, setShowModal] = useState(false);

    // Loading state (buttons etc.), necessary because there can be multiple api calls happening at the same time
    const [isLoading, setIsLoading] = useState(false);

    // File state for vehicle registration file
    const [file, setFile] = useState(null);

    // API Calls
    const {
        data: carData, isFetching: isCarsFetching
    } = useGetCarsQuery();

    const cars = carData?.cars || [];

    const {
        data: drivers, isFetching: isDriversFetching
    } = useGetDriversQuery();

    const {
        data: fuelCards,
    } = useGetFuelCardsQuery();


    const {
        data: user,
    } = useGetUserQuery();

    const {
        data: organisations
    } = useGetOrganisationsQuery();

    const [createCar, {
        isError: carIsError, isSuccess: carIsSuccess, reset: resetCar
    }] = useCreateCarMutation();

    // For adding existing driver to a car
    const [addDriver, {
        isError: driverIsError, isSuccess: driverIsSuccess, reset: resetDriver
    }] = useAddDriverToCarMutation();

    // For creating a new driver
    const [createDriver, {
        isError: driverCreationIsError, reset: resetDriverCreation
    }] = useCreateDriverMutation();

    const [uploadDocument, {
        data: documentData,
        isError: documentIsError, isSuccess: documentIsSuccess, reset: resetDocument
    }] = useUploadDocumentMutation();

    // States for selected items (Keeps track of entire objects, used for the Comboboxes)
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);

    const [selectedDriver, setSelectedDriver] = useState(null);

    const [selectedFuelCard, setSelectedFuelCard] = useState(null);

    const [selectedCar, setSelectedCar] = useState(null);


    // Get isAgent and User Roles, check if FleetWizard should be visible
    const isAgent = useSelector(state => state.auth.isAgentLogin)

    const hasCarManagementRole = user?.roles?.find((role) => role.id === 184) !== undefined;
    // Yue: the repair role is needed for uploading documents. I tested, 184 does not help
    const hasRepairRole = user?.roles?.find((role) => role.id === 177) !== undefined;

    // Tab Names
    const tabs = {
        0: labels?.fleetWizard?.tabs?.vehicle,
        1: labels?.fleetWizard?.tabs?.driver,
        2: labels?.fleetWizard?.tabs?.fuelCard
    }

    // Get the starting tab (if you open the fleet wizard on /drivers, we'd want the driver tab to be open initially)
    const getInitialTabIndex = () => {
        if (!initialTab) return 0

        switch (initialTab.toLowerCase()) {
            case "vehicle":
                return 0
            case "driver":
                return 1
            case "fuelcard":
                return 2
            default:
                return 0
        }
    }

    // State for the current tab
    const [tab, setTab] = useState(getInitialTabIndex);

    // Function to read a file as base64, used when vehicle Registration file is uploaded
    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]); // Remove the data URL prefix
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }


    // Form Submit method/logic for the vehicle form (vehicle tab)
    const onSubmitVehicleForm = (data) => {
        let baseData = {...data};
        delete baseData.licencePlateStartDate;
        delete baseData.organisation;
        delete baseData.mileage;
        delete baseData.mileageDate;
        delete baseData.fuelType;
        delete baseData.registrationDate;
        delete baseData.registrationCountry;
        delete baseData.vehicleType;
        delete baseData.vehicleRegistrationFile;

        const body = {
            baseData: {
                ...baseData,
                status: "Aktiv",
            },
            fleetInformation: {
                customerId: data.organisation,
            },
            additionalCarInformation: {
                baseData: {
                    ...baseData,
                    registrationCountry: data.registrationCountry,
                    vehicleType: data.vehicleType,
                    registeredOn: "Kunde",
                },
                licensePlates: [
                    {
                        licencePlate: data.licencePlate, fromDate: data.licencePlateStartDate
                    }
                ]
            },
        }

        if (data?.fuelType !== null) {
            body.additionalCarInformation.fuelData = {fuelType: data.fuelType}
        }

        if (data?.registrationDate) {
            body.additionalCarInformation.baseData.registrationDate = data.registrationDate;
        }

        if (data?.mileageDate !== null && data?.mileage !== null) {
            // Remove milliseconds from date
            const formattedDate = new Date(data.mileageDate).toISOString().split(".")[0] + "Z";

            body.additionalCarInformation.telematicData = [{
                date: formattedDate, odometerValue: data.mileage
            }]
        }
        // Yue: I was confused why the setIsLoading works here, as useState is async and if we log isLoading afterwareds, isLoading
        // is not changed. The reason why it works here, is that, the api call is also async, and the setIsLoading will finish
        // first before the api call
        setIsLoading(true)
        createCar(body).then(async (result) => {
            if (result?.data?.carId) {
                createDriverForm.setValue("carId", result?.data?.carId);
                createDriverForm.trigger(["carId"])
                
                if (data.vehicleRegistrationFile && file) {
                    const fileDataString = await readFileAsBase64(file);
                    const fileEnding = data.vehicleRegistrationFile.name.split('.')?.pop();
                    const body = {
                        carId: result?.data?.carId,
                        customerId: data.organisation,
                        document: {
                                label: labels?.fleetWizard?.form?.vehicleRegistrationLabel || "Fahrzeugausweis",
                                documentName: `${labels?.fleetWizard?.form?.vehicleRegistrationLabel || "Fahrzeugausweis"}_${data?.licencePlate || result?.data?.carId}.${fileEnding || 'pdf'}`,
                                notes: "Upload on vehicle creation",
                                mimeType: data.vehicleRegistrationFile?.type,
                                visibleInPortal: "true",
                                documentType: 506,
                                file: fileDataString // Wrap the file data in an array as required
                        }
                    }
                    uploadDocument(body).then(() => setIsLoading(false))
                }
            }
            setIsLoading(false)
        })

    }

    // Form Submit method/logic for the driver form (driver tab)
    const onSubmitDriverForm = (data) => {

        // Prepare request data for assigning driver to car
        const requestData = {
            organisationCustomerId: selectedDriver?.parentCustomer,
            carId: data.carId,
            body: {
                startDt: data.startDt,
                driver: selectedDriver?.childCustomer?.id,
                car: data.carId
            }
        }

        // If user didn't select an existing driver, create a new driver
        if (!data.existingDriver) {
            const body = {...data};
            delete body.existingDriver;
            delete body.carId;


            if (data?.childCustomer?.phone?.phoneType?.id === null) {
                delete body.childCustomer.phone;
            }

            body.organisationId = data?.parentCustomer;
            body.roleId = 506;
            body.childCustomer.customerType = {
                id: 2,
                description: "Geschäftskunde"
            }

            //body.childCustomer.status.description = "Aktiv";
            body.childCustomer.mainAddress.country = {id: 200, description: "Schweiz"};
            body.childCustomer.language.description = getLanguageName(Number(data?.childCustomer?.language?.id), labels)

            body.childCustomer.mainAddress = {
                ...body.childCustomer.mainAddress,
                firstName: body.childCustomer.firstName,
                lastName: body.childCustomer.lastName,
            }


            body.childCustomer.companyName = selectedOrganisation?.companyName;
            setIsLoading(true)
            // Send API call to create driver, if successful, modify request data and add the driver to the car
            createDriver(body).then((result) => {
                // If driver creation was successful, set the driver in the request data and add driver to car
                if (result?.data) {
                    const newDriver = result?.data;           
                    requestData.body.driver = newDriver?.childCustomer?.id;
                    requestData.organisationCustomerId = newDriver?.parentCustomer;
                    addDriver(requestData).then(() => setIsLoading(false))
                }
                else {
                    setIsLoading(false)
                }
            })
            // If user is just assigning existing driver, add driver to car
        } else {
            addDriver(requestData).then(() => {
                setIsLoading(false)
            })
        }

    }


    // Placeholder, will hold fuelcard logic
    const onSubmit = (data) => {
        // nothing
    }

    // Init countries, needed because the label loading is weird, without this the countries const below would crash
    const countryList = labels?.fleetWizard?.form?.countries || {};

    // Get labels for countries to display in select registrationCountry
    const countries = Object.keys(countryList)?.map((country) => (
        {
            value: country,
            label: labels?.fleetWizard?.form?.countries[country]
        }
    ))

    const languages = [
        {id: 1, description: labels?.otherElements?.languages?.german},
        {id: 2, description: labels?.otherElements?.languages?.english},
        {id: 3, description: labels?.otherElements?.languages?.french},
        {id: 4, description: labels?.otherElements?.languages?.italian},
    ]

    const salutations = [
        {id: 0, description: "-"},
        {id: 1, description: labels?.driverPage?.driverInfo?.salutationMale},
        {id: 2, description: labels?.driverPage?.driverInfo?.salutationFemale},
    ]

    const phoneTypes = [
        {value: 1, label: "Telefon (Festnetz)"},
        {value: 2, label: "Telefon (Mobil)"},
        {value: 3, label: "Telefon (weitere)"},
        {value: 6, label: "Fax"},
    ]


    // Form logic for the vehicle form (resolver and defaultValues)
    const createCarForm = useForm({
        resolver: zodResolver(createCarSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Vehicle Info
            organisation: "",
            registrationCountry: labels?.fleetWizard?.form?.countries?.Schweiz || "Schweiz",
            vin: "",
            licencePlate: "",
            licencePlateStartDate: null,
            brand: "",
            type: "",
            model: "",
            vehicleType: "",
            mileage: "",
            mileageDate: null,
            fuelType: "",
            registrationDate: null,
            vehicleRegistrationFile: "",
            serialNumber: "",
            typeCertificationNumber: "",
        },
    });

    // Form logic for the driver form (resolver and defaultValues)
    const createDriverForm = useForm({
        resolver: zodResolver(createDriverSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Driver Info
            carId: "", // Sent to API to add to car
            existingDriver: false, // Not sent in API
            // Below is what we send to API to create a new driver
            parentCustomer: "",
            startDt: null,
            childCustomer: {
                firstName: "",
                lastName: "",
                email: "",
                birthday: null,
                status: {
                    id: 1
                },
                salutation: {
                    id: null
                },
                phone: {
                    countryCode: "",
                    cli: "",
                    areaCode: "",
                    phone: "",
                    phoneType: {
                        id: null
                    }
                },
                mainAddress: {
                    street: "",
                    zip: "",
                    city: "",
                    houseNumber: "",
                    //country: {id: "", description: ""}
                },
                language: {
                    id: 1,
                }
            }
        }
    })

    // Form logic for the fuel card form (resolver and defaultValues)
    const createFuelCardForm = useForm({
        resolver: zodResolver(createFuelCardSchema(labels?.fleetWizard?.form)), defaultValues: {
            // Fuel Card Info
            existingFuelCard: false,
            fuelCardNumber: "",
            fuelCardName: ""
        }
    })


    // Reset the modal, reset the form and states (called when "Cancel" is clicked)
    function resetModal() {
        resetCar();
        resetDriver();
        resetDocument();
        resetDriverCreation();
        //resetFuelCard();
        createCarForm.reset() // Reset fields
        createDriverForm.reset() // Reset fields
        createFuelCardForm.reset() // Reset fields
        setFile(null) // Reset file (if any was uploaded)
        setSelectedDriver(null) // Reset selected driver
        setSelectedFuelCard(null) // Reset selected fuel card
        setSelectedCar(null) // Reset selected car
        setSelectedOrganisation(null) // Reset selected organisation
    }

    // I initially wanted to make it more dynamic so we only had to place this component once but because the width would be weird I had to place it in each
    // part/tab of the FleetWizard, which technically means we could improve the logic a bit (e.g. make it possible to have error messages on 2 tabs at same time etc)
    const ErrorMessage = () => {
        // Find where error happened (vehicle creation, driver creation, fuelcard creation)
        let errorTab;
        // If car creation failed, set errorTab to 0
        if (carIsError) errorTab = 0;
        // If driver creation failed, set errorTab to 1
        if (driverIsError || driverCreationIsError) errorTab = 1;


        // Means there was no error, don't show error message
        if (!errorTab && errorTab !== 0) return null;

        // For the error message, we have 3 different messages, depending on which tab the error happened
        const errorMessageLabels = ["vehicleNotAdded", "driverNotAdded", "fuelCardNotAdded"]

        let message = labels?.fleetWizard?.messages?.error[errorMessageLabels[errorTab]];

        // If error was caused by driver creation, show driver creation error message
        if (driverCreationIsError) {
            message = labels?.fleetWizard?.messages?.error?.driverNotCreated;
        }

        // Scroll to top of modal when error message is shown (id is modal-tab-0, modal-tab-1, modal-tab-2)
        if (errorTab !== undefined) {
            const modalTab = document.getElementById(`modal-tab-${errorTab}`);
            if (modalTab) modalTab.scrollTop = 0;
        }


        return (<StatusMessage variant="warning" style={{marginBottom: "0.5em"}} hidden={tab !== errorTab}>
                <StatusMessageIcon>
                    <ErrorIcon/>
                </StatusMessageIcon>
                <StatusMessageTitle>
                    {labels?.fleetWizard?.messages?.error?.title}
                </StatusMessageTitle>
                <StatusMessageContent>
                    {message}
                </StatusMessageContent>
            </StatusMessage>)
    }

    // Success message for the FleetWizard, shows when a car or driver has been successfully added
    const SuccessMessage = () => {
        let successTab, showAdditionalButton;
        if (carIsSuccess) {
            successTab = 0;
            showAdditionalButton = true;
        }
        if (driverIsSuccess) {
            successTab = 1;
            showAdditionalButton = true;
        }

        // If no RTK mutation has isSuccess, don't show anything
        if (!successTab && successTab !== 0) return null;

        const successMessageLabels = ["vehicleAdded", "driverAdded", "fuelCardAdded"]

        const AdditionalButton = () => {
            if (!showAdditionalButton) return null;
            let label;
            if (successTab === 0) label = labels?.fleetWizard?.titles?.addDriver;
            if (successTab === 1) label = labels?.fleetWizard?.titles?.addFuelCard;

            return (
                <button
                    className="btn btn-primary sm"
                    style={{width: "100%"}}
                    disabled={successTab === 1} // Disabled because fuel card adding is not implemented
                    onClick={() => {
                        if (successTab === 0) {
                            const newCarId = createDriverForm.getValues("carId");
                            const newCar = cars.find(car => car?.additionalCarInformation?.baseData?.carId === newCarId);
                            setSelectedCar(newCar);
                            createCarForm.reset();
                            setTab(1)
                            resetCar();
                            resetDocument();
                        }

                    }}
                >
                    <PlusIcon style={{marginRight: "4px", stroke: "white"}}/>
                    {label}
                </button>
            )
        }


        // Below the Success Message, we would also show a Warning Message, if a file (vehicleRegistration) was uploaded but the API call failed
        return (
            <>
                <StatusMessage variant="success" style={{marginBottom: "1em", marginTop: "1em"}}>
                    <StatusMessageIcon>
                        <SuccessIcon/>
                    </StatusMessageIcon>
                    <StatusMessageTitle>
                        {labels?.fleetWizard?.messages?.success?.title}
                    </StatusMessageTitle>
                    <StatusMessageContent>
                        {labels?.fleetWizard?.messages?.success[successMessageLabels[successTab]]}
                    </StatusMessageContent>
                </StatusMessage>

                {
                    // If the document upload failed (but the car was created successfully), show a warning message (note: documentId 0 also means failed)
                    documentIsError || (documentIsSuccess && !documentData.documentId) ? (
                        <StatusMessage variant="warning" style={{marginBottom: "1em"}}>
                            <StatusMessageIcon>
                                <ErrorIcon/>
                            </StatusMessageIcon>
                            <StatusMessageTitle>
                                {labels?.fleetWizard?.messages?.error?.title}
                            </StatusMessageTitle>
                            <StatusMessageContent>
                                {labels?.fleetWizard?.messages?.error?.documentNotUploaded}
                            </StatusMessageContent>
                        </StatusMessage>
                    ) : null
                }

                <div className="footer-buttons">
                    <button
                        className={showAdditionalButton ? "btn btn-light sm" : "btn btn-primary sm"}
                        style={{width: "100%"}}
                        onClick={() => {
                            resetModal();
                            setShowModal(false);
                        }}
                    >
                        <CloseIcon style={{marginRight: "4px", stroke: showAdditionalButton ? "gray" : "white"}}/>
                        {labels?.otherElements?.close}
                    </button>

                    <AdditionalButton/>
                </div>

            </>

        )
    }

    const isLoadingAnything = isLoading || isCarsFetching || isDriversFetching;
    const isLoadingAnythingCarTab = isLoading;

    // Keeps track of whether any part of the form is successful, used for showing success message
    const isSuccess = carIsSuccess || driverIsSuccess;

    // Checks for user flag controlling whether user can upload vehicle registration file
    const hasVehicleRegistrationUploadFlag =  useVariableValue("fleet-wizard-upload", false)


    // If user is not an agent or doesn't have car management role, don't show the FleetWizard
    const hasFleetWizardFeatureFlag =  useVariableValue("fleet-wizard", false)
    if (!labels || !((isAgent || hasFleetWizardFeatureFlag) && hasCarManagementRole)) return null



    // Get the labels for the fuel types and vehicle types, used in the select fields
    const fuelTypes = Object.keys(labels?.fleetWizard?.form?.fuelTypes)?.map((fuelType) => ({
        value: fuelType,
        label: labels?.fleetWizard?.form?.fuelTypes[fuelType]
        }))


    // Get the labels for the vehicle types, used in the select fields
    const vehicleTypes = Object.keys(labels?.fleetWizard?.form?.vehicleTypes)?.map((vehicleType) => ({
            value: vehicleType,
            label: labels?.fleetWizard?.form?.vehicleTypes[vehicleType]
        }))



    // Get Modal title from current tab
    const getModalTitle = () => {
        switch(tab) {
            case 0:
                return labels?.fleetWizard?.titles?.addVehicle;
            case 1:
                return labels?.fleetWizard?.titles?.addDriver;
            case 2:
                return labels?.fleetWizard?.titles?.addFuelCard;
            default:
                return labels?.fleetWizard?.titles?.addVehicle;
        }
    }

    // Get Modal icon from current tab
    const getModalIcon = () => {
        switch(tab) {
            case 0:
                return <CarIcon style={{fill: "white", stroke: "black"}}/>
            case 1:
                return <DriverIcon style={{fill: "white", stroke: "black"}}/>
            case 2:
                return <CardIcon style={{fill: "white", stroke: "black"}}/>
            default:
                return <CarIcon style={{fill: "white", stroke: "black"}}/>
        }
    }

    // If there is only one organisation, set it as default value for the organisation select field
    if (organisations && organisations.length === 1 && !selectedOrganisation){
        createCarForm.setValue("organisation", organisations[0].id);
        createDriverForm.setValue("parentCustomer", organisations[0].id);
        if (!selectedOrganisation) setSelectedOrganisation(organisations[0]);
    }
    // If there are no organisations (which shouldn't happen), set the user's customer as default value for the organisation select field
    if (organisations && organisations.length === 0 && !selectedOrganisation){
        createCarForm.setValue("organisation", user?.customer);
        createDriverForm.setValue("parentCustomer", user?.customer);
        if (!selectedOrganisation) setSelectedOrganisation({
            id: user?.customer,
            companyName: "-"
        });
    }

    const formControlButtons = (<FormControlButtons
            labels={labels}
            onCancel={() => {
                resetModal();
                setShowModal(false);
            }}
            // there are some isLoading status that are not relavant for the car tab
            isLoading={tab===0?isLoadingAnythingCarTab:isLoadingAnything}
        />
    )

    return (<>
            <FormModal title={getModalTitle()} show={showModal} setShow={setShowModal}
                       onClose={() =>{
                           resetModal();
                           setShowModal(false)
                       }}
                       onOutsideClick={() => setShowModal(false)}
                       featuredIcon={<FeatureIcon>{getModalIcon()}</FeatureIcon>}
            >

                {isSuccess  ? (<SuccessMessage/>) : (
                    <>
                        {/* MODAL TABS  */}
                        <div className="modal-tabs">
                            {Object.keys(tabs).map((key, index) => (
                                <button key={index} type="button" className="modal-tab"
                                        data-selected={tab === index}
                                        onClick={() => setTab(index)}
                                        disabled={index===2}
                                >
                                    {tabs[key]}
                                </button>))}
                        </div>

                        {/* VEHICLE TAB  */}
                        <form onSubmit={createCarForm.handleSubmit(onSubmitVehicleForm)} className="form"
                              hidden={tab !== 0 }>
                            <div className="modal-tab-content" data-selected={tab === 0} id={"modal-tab-0"}>
                                <ErrorMessage/>
                                <FormField
                                    control={createCarForm.control}
                                    name="organisation"
                                    render={({field, error}) => (
                                        <FormItem hidden={organisations?.length === 1 || organisations?.length === 0}>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.organisation}
                                        </FormLabel>

                                        <Combobox
                                            selected={selectedOrganisation?.id}
                                            onSelect={(id) => {
                                                const organisation = organisations.find(organisation => organisation?.id === id);
                                                setSelectedOrganisation(organisation)
                                                createCarForm.setValue("organisation", organisation?.id || null);
                                                createCarForm.trigger(["organisation"])
                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={`${selectedOrganisation?.companyName}`}
                                                           disabled={organisations?.length===1 || organisations?.length===0}
                                                           data-invalid={!!error?.message}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {organisations?.map(organisation => (<ComboboxItem key={organisation?.id}
                                                                                 value={organisation?.id}>
                                                    {`${organisation?.companyName}`}
                                                </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>

                                        <FormMessage message={error?.message}/>

                                    </FormItem>)}
                                />

                                <FormField
                                    control={createCarForm.control}
                                    name="registrationCountry"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.approvedIn}
                                        </FormLabel>
                                        <Select
                                            options={countries}
                                            {...field}
                                            {...error}
                                        />
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />

                                <FormField
                                    control={createCarForm.control}
                                    name="vin"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo.VIN} *
                                        </FormLabel>
                                        <Input placeholder="WBYK283920189393" {...field} {...error} maxLength={17} type="text"/>
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />


                                <FormGroup>
                                    <FormField
                                        control={createCarForm.control}
                                        name="licencePlate"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.carsPage?.carInfo.plateNumber} *
                                            </FormLabel>
                                            <Input placeholder="ZH 392021" {...field} {...error} type="text"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createCarForm.control}
                                        name="licencePlateStartDate"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.fleetWizard?.form?.licensePlateStartDate} *
                                            </FormLabel>
                                            <Input {...field} {...error} type="date"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />
                                </FormGroup>



                                <FormGroup>

                                    <FormField
                                        control={createCarForm.control}
                                        name="brand"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.carsPage?.carInfo.brand} *
                                            </FormLabel>
                                            <Input placeholder="BMW" {...field} {...error} type="text"/>
                                            {/*<FormDescription>This is a form description for Brand</FormDescription>*/}
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />
                                    <FormField
                                        control={createCarForm.control}
                                        name="type"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.carsPage?.carInfo.type} *
                                            </FormLabel>
                                            <Input placeholder="X5" {...field} {...error} type="text"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />
                                </FormGroup>

                                <FormField
                                    control={createCarForm.control}
                                    name="model"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo?.model}
                                        </FormLabel>
                                        <Input placeholder="M-Sport" {...field} {...error} type="text"/>
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />


                                <FormField
                                    control={createCarForm.control}
                                    name="vehicleType"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo?.carPurpose}
                                        </FormLabel>

                                        <Select
                                            options={vehicleTypes}
                                            {...field}
                                            {...error}
                                        />

                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />

                                <FormGroup>

                                    <FormField
                                        control={createCarForm.control}
                                        name="serialNumber"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.carsPage?.carInfo?.serialNumber}
                                            </FormLabel>
                                            <Input placeholder="" {...field} {...error} type="text"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />
                                    <FormField
                                        control={createCarForm.control}
                                        name="typeCertificationNumber"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.carsPage?.carInfo?.typeCertificationNumber}
                                            </FormLabel>
                                            <Input placeholder="" {...field} {...error} type="text"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />
                                </FormGroup>


                                <FormField
                                    control={createCarForm.control}
                                    name="fuelType"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.carsPage?.carInfo?.fuelType}
                                        </FormLabel>
                                        <Select
                                            options={fuelTypes}
                                            {...field}
                                            {...error}
                                        />
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />

                                <FormGroup>

                                    <FormField
                                        control={createCarForm.control}
                                        name="mileage"
                                        render={({field, error}) => (<FormItem>
                                            <FormLabel {...error} >
                                                {labels?.fleetWizard?.form?.mileage}


                                            </FormLabel>
                                            <Input placeholder="20000" {...field} {...error} type="number"/>
                                            <FormMessage message={error?.message}/>
                                        </FormItem>)}
                                    />

                                    <FormField
                                        control={createCarForm.control}
                                        name="mileageDate"
                                        render={({field, error}) => {
                                            const mileageValue = createCarForm.watch("mileage");
                                            const mileageDateMandatory = mileageValue !== "" && mileageValue !== null;

                                            let actualError;

                                            if (mileageDateMandatory) actualError = error;
                                            else actualError = {}

                                            return (<FormItem>
                                                <FormLabel {...actualError} >
                                                    {labels?.fleetWizard?.form?.mileageDate} {mileageDateMandatory ? " *" : ""}

                                                </FormLabel>
                                                <Input {...field} {...actualError} type="date"/>
                                                <FormMessage message={actualError?.message}/>
                                            </FormItem>)
                                        }
                                    }
                                    />

                                </FormGroup>

                                <FormField
                                    control={createCarForm.control}
                                    name="registrationDate"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.registrationDate}
                                        </FormLabel>
                                        <Input {...field} {...error} type="date"/>
                                        <FormMessage message={error?.message}/>
                                    </FormItem>)}
                                />

                                {
                                  hasVehicleRegistrationUploadFlag && hasRepairRole && (
                                        <FormField
                                            control={createCarForm.control}
                                            name="vehicleRegistrationFile"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error?.type} >
                                                    {labels?.fleetWizard?.form?.vehicleRegistration}
                                                </FormLabel>
                                                <Input {...field} {...error?.type} type="file" onFileChange={(file) => setFile(file)} placeholder={labels?.otherElements?.dragAndDrop}/>
                                                <FormMessage message={error?.type?.message}/>

                                            </FormItem>)}
                                        />
                                    )
                                }


                            </div>
                            {formControlButtons}

                        </form>


                        {/* DRIVER TAB  */}
                        <form onSubmit={createDriverForm.handleSubmit(onSubmitDriverForm)} className="form"
                              hidden={tab !== 1}>

                            <div className="modal-tab-content" data-selected={tab === 1} id={"modal-tab-1"}>
                                <ErrorMessage/>


                                <FormField
                                    control={createDriverForm.control}
                                    name="carId"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.selectCarTitle} *
                                        </FormLabel>

                                        <Combobox
                                            selected={selectedCar?.additionalCarInformation?.baseData?.carId}
                                            onSelect={(id) => {
                                                const car = cars.find(car => car?.additionalCarInformation?.baseData?.carId === id);
                                                setSelectedCar(car);
                                                createDriverForm.setValue("carId", car?.additionalCarInformation?.baseData?.carId || "");
                                                createDriverForm.trigger(["carId"])
                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={`${selectedCar?.brand} ${(selectedCar?.type || "")} (${selectedCar?.additionalCarInformation?.baseData?.vin})`}
                                                           data-invalid={!!error?.message}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {cars?.map(car => (<ComboboxItem key={car?.additionalCarInformation?.baseData?.carId}
                                                                                 value={car?.additionalCarInformation?.baseData?.carId}>
                                                    {`${car?.brand} ${(car?.type || "")} (${car?.additionalCarInformation?.baseData?.vin})`}
                                                </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>

                                        <FormMessage message={error?.message}/>

                                        <FormDescription>
                                            {labels?.fleetWizard?.form?.selectCarDescription}
                                        </FormDescription>

                                    </FormItem>)}
                                />





                                <FormField
                                    control={createDriverForm.control}
                                    name="existingDriver"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.existingDriver}
                                        </FormLabel>
                                        <Input {...field} {...error}
                                               checked={createDriverForm.watch("existingDriver")}
                                               type="checkbox"
                                               onChange={(e) => {
                                                   createDriverForm.setValue("existingDriver", e.target.checked);
                                                   // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)
                                                   if (!e.target.checked) {
                                                       setSelectedDriver(null);
                                                   }


                                               }}
                                        />
                                    </FormItem>)}
                                />

                                {createDriverForm.watch("existingDriver") && (<div>
                                        <Combobox
                                            selected={selectedDriver?.childCustomer?.id}
                                            onSelect={(id) => {
                                                const driver = drivers.find(driver => driver?.childCustomer?.id === id);
                                                setSelectedDriver(driver || null);
                                                createDriverForm.setValue("parentCustomer", driver?.parentCustomer || "");
                                                createDriverForm.setValue("childCustomer.firstName", driver?.childCustomer?.firstName || "");
                                                createDriverForm.setValue("childCustomer.lastName", driver?.childCustomer?.lastName || "");
                                                createDriverForm.setValue("childCustomer.email", driver?.childCustomer?.email || "");
                                                createDriverForm.setValue("childCustomer.birthday", driver?.childCustomer?.birthday || null);
                                                createDriverForm.setValue("childCustomer.salutation.id", driver?.childCustomer?.salutation.id ?? null);
                                                createDriverForm.setValue("childCustomer.phone.countryCode", driver?.childCustomer?.phone?.countryCode || "");
                                                createDriverForm.setValue("childCustomer.phone.cli", driver?.childCustomer?.phone?.cli || "");
                                                createDriverForm.setValue("childCustomer.phone.areaCode", driver?.childCustomer?.phone?.areaCode || "");
                                                createDriverForm.setValue("childCustomer.phone.phone", driver?.childCustomer?.phone?.phone || "");
                                                createDriverForm.setValue("childCustomer.phone.phoneType.id", driver?.childCustomer?.phone?.phoneType?.id || "");
                                                createDriverForm.setValue("childCustomer.mainAddress.street", driver?.childCustomer?.mainAddress?.street || "");
                                                createDriverForm.setValue("childCustomer.mainAddress.zip", driver?.childCustomer?.mainAddress?.zip || "");
                                                createDriverForm.setValue("childCustomer.mainAddress.city", driver?.childCustomer?.mainAddress?.city || "");
                                                createDriverForm.setValue("childCustomer.mainAddress.houseNumber", driver?.childCustomer?.mainAddress?.houseNumber || "");

                                                //createDriverForm.setValue("childCustomer.mainAddress.country.id", driver?.childCustomer?.mainAddress?.country?.id || null);


                                                // Trigger  all fields we changed above (to validate)
                                                createDriverForm.trigger(["parentCustomer", "childCustomer.firstName", "childCustomer.lastName", "childCustomer.email","childCustomer.salutation.id", "childCustomer.birthday","childCustomer.phone.phoneType.id", "childCustomer.phone.countryCode", "childCustomer.phone.cli", "childCustomer.phone.areaCode", "childCustomer.phone.phone", "childCustomer.mainAddress.street", "childCustomer.mainAddress.zip", "childCustomer.mainAddress.city", "childCustomer.mainAddress.houseNumber", "childCustomer.mainAddress.country.id"])
                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={selectedDriver?.childCustomer?.firstName + " " + selectedDriver?.childCustomer?.lastName}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {drivers?.map(driver => (<ComboboxItem key={driver?.childCustomer?.id}
                                                                                       value={driver?.childCustomer?.id}>
                                                        {driver?.childCustomer?.firstName} {driver?.childCustomer?.lastName}
                                                    </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>
                                    <FormDescription>
                                        {labels?.fleetWizard?.form?.selectDriverDescription}
                                    </FormDescription>
                                    </div>)

                                }

                                {(!createDriverForm.watch("existingDriver") || (createDriverForm.watch("existingDriver") && selectedDriver)) && (<>

                                    <FormField
                                        control={createDriverForm.control}
                                        name="parentCustomer"
                                        render={({field, error}) => (
                                            <FormItem
                                                hidden={(organisations?.length === 1 || organisations?.length === 0) && !createDriverForm.watch("existingDriver")}
                                            >
                                            <FormLabel {...error} >
                                                {labels?.driverPage?.driverInfo?.companyName} *
                                            </FormLabel>

                                            {
                                                createDriverForm.watch("existingDriver") ? (
                                                    <FormValue>
                                                        {selectedDriver?.parentCompanyName}
                                                    </FormValue>
                                                ) : (
                                                    <Combobox
                                                        selected={selectedOrganisation?.id}
                                                        onSelect={(id) => {
                                                            const organisation = organisations.find(organisation => organisation?.id === id);
                                                            setSelectedOrganisation(organisation)
                                                            createDriverForm.setValue("parentCustomer", organisation?.id || null);
                                                            createDriverForm.trigger(["parentCustomer"])
                                                        }}
                                                    >
                                                        <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                                       selectedValue={`${selectedOrganisation?.companyName}`}
                                                                       disabled={organisations?.length===1 || organisations?.length===0}
                                                                       data-invalid={!!error?.message}
                                                        />
                                                        <ComboboxList
                                                            noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                                        >
                                                            {organisations?.map(organisation => (<ComboboxItem key={organisation?.id}
                                                                                                               value={organisation?.id}>
                                                                {`${organisation?.companyName}`}
                                                            </ComboboxItem>))}
                                                        </ComboboxList>
                                                    </Combobox>
                                                )
                                            }

                                                <FormMessage message={error?.message}/>

                                        </FormItem>)}
                                    />



                                    <FormGroup>
                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.firstName"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.firstName} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.firstName || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Input
                                                            placeholder="Max"
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }

                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.lastName"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.familyName} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.lastName || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Input
                                                            placeholder="Muster"
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }
                                                <FormMessage message={error?.message}/>

                                            </FormItem>)}
                                        />
                                    </FormGroup>

                                    <FormGroup>

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.email"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.email} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.email || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Input
                                                            placeholder="max.muster@mail.com"
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }

                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.salutation.id"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.salutation} *

                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {salutations.find((salutation) => salutation.id === selectedDriver?.childCustomer?.salutation?.id)?.description || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Select
                                                            options={salutations.filter((salutation) => salutation.id !== 0).map((salutation) => ({value: salutation.id, label: salutation.description}))}
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }

                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />

                                    </FormGroup>




                                    <FormGroup>

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.birthday"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.birthday}
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {formatDateIfNotEmpty(selectedDriver?.childCustomer?.birthday) || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Input
                                                            {...field} {...error} type="date"
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.language.id"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.language}
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {getLanguageName(selectedDriver?.childCustomer?.language?.id, labels) || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Select
                                                            options={languages.map((language) => ({value: language.id, label: language.description}))}
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }
                                            </FormItem>)}
                                        />
                                    </FormGroup>



                                    {
                                        createDriverForm.watch("existingDriver") ? (
                                            <FormItem>
                                                <FormLabel>
                                                    {labels?.driverPage?.driverInfo?.phone}
                                                </FormLabel>
                                                <FormValue>
                                                    {selectedDriver?.childCustomer?.phone?.phone || "-"}
                                                </FormValue>
                                            </FormItem>

                                        ) : (
                                            <FormItem >
                                                <FormLabel>
                                                    {labels?.driverPage?.driverInfo?.phone}
                                                </FormLabel>
                                                <FormGroup>
                                                    <FormField
                                                        control={createDriverForm.control}
                                                        name="childCustomer.phone.phoneType.id"
                                                        render={({field, error}) => (<FormItem style={{width: "250px"}}>
                                                            <FormLabel hidden>
                                                                {labels?.driverPage?.driverInfo?.phone}
                                                            </FormLabel>
                                                            <Select options={phoneTypes} {...field} {...error} disabled={createDriverForm.watch("existingDriver")}
                                                            />
                                                        </FormItem>)}
                                                    />


                                                    <FormField
                                                        control={createDriverForm.control}
                                                        name="childCustomer.phone.countryCode"
                                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                                            <FormLabel hidden>
                                                                {labels?.driverPage?.driverInfo?.phone}
                                                            </FormLabel>
                                                            <Input
                                                                {...field} {...error} placeholder="+41"
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />
                                                        </FormItem>)}
                                                    />

                                                    <FormField
                                                        control={createDriverForm.control}
                                                        name="childCustomer.phone.areaCode"
                                                        render={({field, error}) => (<FormItem style={{width: "150px"}}>
                                                            <FormLabel hidden>
                                                                {labels?.driverPage?.driverInfo?.phone}
                                                            </FormLabel>
                                                            <Input
                                                                {...field} {...error} placeholder="79" type="number"
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />
                                                        </FormItem>)}
                                                    />

                                                    <FormField
                                                        control={createDriverForm.control}
                                                        name="childCustomer.phone.cli"
                                                        render={({field, error}) => (<FormItem>
                                                            <FormLabel hidden>
                                                                {labels?.driverPage?.driverInfo?.phone}
                                                            </FormLabel>
                                                            <Input
                                                                {...field} {...error} placeholder="123 45 67" type="number"
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />
                                                        </FormItem>)}
                                                    />

                                                </FormGroup>
                                            </FormItem>


                                        )
                                    }


                                    <FormGroup>
                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.mainAddress.street"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.street} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.mainAddress?.street || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <>
                                                            <Input
                                                                {...field} {...error}
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />
                                                            <FormMessage message={error?.message}/>
                                                        </>

                                                    )
                                                }
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.mainAddress.houseNumber"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.houseNumber}
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.mainAddress?.houseNumber || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <Input
                                                            {...field} {...error}
                                                            disabled={createDriverForm.watch("existingDriver")}
                                                        />
                                                    )
                                                }
                                            </FormItem>)}
                                        />


                                    </FormGroup>

                                    <FormGroup>
                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.mainAddress.zip"

                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.zip} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.mainAddress?.zip || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <>
                                                            <Input
                                                                {...field} {...error} maxLength={5}
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />

                                                            <FormMessage message={error?.message}/>

                                                        </>

                                                    )
                                                }
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createDriverForm.control}
                                            name="childCustomer.mainAddress.city"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.driverPage?.driverInfo?.city2} *
                                                </FormLabel>

                                                {
                                                    createDriverForm.watch("existingDriver") ? (
                                                        <FormValue>
                                                            {selectedDriver?.childCustomer?.mainAddress?.city || "-"}
                                                        </FormValue>
                                                    ) : (
                                                        <>
                                                            <Input
                                                                {...field} {...error}
                                                                disabled={createDriverForm.watch("existingDriver")}
                                                            />

                                                            <FormMessage message={error?.message}/>
                                                        </>

                                                    )
                                                }
                                            </FormItem>)}
                                        />

                                    </FormGroup>


                                        <FormField
                                            control={createDriverForm.control}
                                            name="startDt"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    {labels?.fleetWizard?.form?.driverStartDate} *
                                                </FormLabel>
                                                <Input {...field} {...error} type="date"/>
                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />
                                    </>)}
                            </div>
                            {formControlButtons}

                        </form>



                        {/* FUEL CARD TAB  */}
                        <form onSubmit={createFuelCardForm.handleSubmit(onSubmit)} className="form"
                              hidden={tab !== 2}>
                            <div className="modal-tab-content" data-selected={tab === 2} id={"modal-tab-2"}>
                                <ErrorMessage/>

                                <FormField
                                    control={createFuelCardForm.control}
                                    name="existingFuelCard"
                                    render={({field, error}) => (<FormItem>
                                        <FormLabel {...error} >
                                            {labels?.fleetWizard?.form?.existingFuelCard}
                                        </FormLabel>
                                        <Input {...field} {...error}
                                               checked={createFuelCardForm.watch("existingFuelCard")}
                                               type="checkbox"
                                               onChange={(e) => {
                                                   createFuelCardForm.setValue("existingFuelCard", e.target.checked);
                                                   // If the checkbox is unchecked, reset the selected driver (otherwise a bug/exploit can occur)
                                                   if (!e.target.checked) {
                                                       setSelectedFuelCard(null);
                                                   }


                                               }}
                                        />

                                    </FormItem>)}
                                />

                                {createFuelCardForm.watch("existingFuelCard") && (<div>
                                        <Combobox
                                            selected={selectedFuelCard?.id}
                                            onSelect={(id) => {
                                                const fuelCard = fuelCards.find(fuelCard => fuelCard?.id === id);
                                                setSelectedFuelCard(fuelCard);
                                                createFuelCardForm.setValue("fuelCardNumber", fuelCard?.cardNumber);
                                                createFuelCardForm.setValue("fuelCardName", fuelCard?.cardName);
                                                // Check form again for errors (to clear if there were any beforehand without needing user
                                                // to type in input field
                                                createFuelCardForm.trigger(["fuelCardNumber", "fuelCardName"])
                                            }}
                                        >
                                            <ComboboxInput placeholder={labels?.otherElements?.pleaseSelect}
                                                           selectedValue={selectedFuelCard?.cardNumber}
                                            />
                                            <ComboboxList
                                                noResults={<ComboboxItem disabled>{labels?.otherElements?.noResultsFound}</ComboboxItem>}
                                            >
                                                {fuelCards?.map(fuelCard => (
                                                    <ComboboxItem key={fuelCard.id} value={fuelCard.id}>
                                                        {fuelCard.cardNumber}
                                                    </ComboboxItem>))}
                                            </ComboboxList>
                                        </Combobox>
                                    <FormDescription>
                                        {labels?.fleetWizard?.form?.selectDriverDescription}
                                    </FormDescription>
                                    </div>)

                                }

                                {(!createFuelCardForm.watch("existingFuelCard") || (createFuelCardForm.watch("existingFuelCard") && selectedFuelCard)) && (<>
                                        <FormField
                                            control={createFuelCardForm.control}
                                            name="fuelCardNumber"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    Tankkartennummer *
                                                </FormLabel>
                                                <Input
                                                    placeholder=""
                                                    {...field} {...error}
                                                    disabled={createFuelCardForm.watch("existingFuelCard")}
                                                />
                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />

                                        <FormField
                                            control={createFuelCardForm.control}
                                            name="fuelCardName"
                                            render={({field, error}) => (<FormItem>
                                                <FormLabel {...error} >
                                                    Tankkartenname *
                                                </FormLabel>
                                                <Input
                                                    placeholder=""
                                                    {...field}
                                                    {...error}
                                                    disabled={createFuelCardForm.watch("existingFuelCard")}
                                                />
                                                <FormMessage message={error?.message}/>
                                            </FormItem>)}
                                        />

                                    </>)}

                            </div>
                            {formControlButtons}
                        </form>
                    </>
                )}

            </FormModal>

            {/* FLEET WIZARD TRIGGER BUTTON (OPENS MODAL)  */}
            <button className="btn btn-primary sm" style={{width: "fit-content", justifySelf: "end"}}
                    onClick={() => setShowModal(true)} type="button"
                    {...props}
            >
                <PlusIcon style={{marginRight: "4px", stroke: "white"}}/>
                {props.children}
            </button>
        </>

    );
}

export default FleetWizard;